import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FunctionComponent } from "react";
import { RegisterContext } from '../../pages/Register/Register';
import { API_BASE_URL, configAuthApi } from "../../constants/apiConstants";
import IntlTelInput from "react-intl-tel-input";

interface specializationInterface {
    id: number;
    name: string;
}

const Step4: FunctionComponent = () => {
    const { company, setCompany, setLoading, validators } = useContext(RegisterContext);
    const [specializations, setSpecializations] = useState<specializationInterface[]>([]);

    const companyPhoneRef = useRef<IntlTelInput>(null);
    const representativePhoneRef = useRef<IntlTelInput>(null);

    useEffect(()=>{
        setLoading?.(true);

        axios.get(`${ API_BASE_URL }/companies/specializations`, configAuthApi())
            .then(response => {
                if (response.status == 200) {
                    setSpecializations(response.data.data);
                }
            })
            .finally(() => {
                setLoading?.(false);
                setTimeout(() => {
                    if (
                        company?.company_mobile &&
                        company?.company_phone
                    ) {
                        setCompany?.({ ...company, company_phone: company.company_mobile });
                    }
        
                    if (
                        company?.representative_mobile &&
                        company?.representative_phone
                    ) {
                        setCompany?.({ ...company, representative_phone: company.representative_mobile });
                    }
                }, 300);
            });
    }, []);

    const isNameValid = () => !company?.name || company.name.length == 0 || validators?.company.name();
    const isSpecializationValid = () => !company?.company_specialization_id || validators?.company.company_specialization_id();
    const isCapitalValid = () => !company?.capital || company.capital.length == 0 || validators?.company.capital();
    const isTaxNumberValid = () => !company?.tax_number || company.tax_number.length == 0 || validators?.company.tax_number();
    const isCompanyEmailValid = () => !company?.email || company.email.length == 0 || validators?.company.email();
    const isAddressValid = () => !company?.address || company.address.length == 0 || validators?.company.address();
    const isEmployeeCountValid = () => (!company?.count_employees && company?.count_employees !== 0) || validators?.company.count_employees();
    const isCompanyPhoneValid = () => !company?.company_phone || company.company_phone.length == 0 || validators?.company.company_phone();

    const isRepresentativeNameValid = () => (
        !company?.representative_name ||
        company.representative_name.length == 0 ||
        validators?.company.representative_name()
    );

    const isRepresentativePhoneValid = () => (
        !company?.representative_phone ||
        company.representative_phone.length == 0 ||
        validators?.company.representative_phone()
    );


    const isRepresentativeEmailValid = () => (
        !company?.representative_email ||
        company.representative_email.length == 0 ||
        validators?.company.representative_email()
    );

    const setCompanyField = (field: string, value: string) => setCompany?.({ ...company, [field]: value });
    const setCompanyIntField = (field: string, value: number) => setCompany?.({ ...company, [field]: value });

    const setAddress = (value: string) => value.length == 0
            ? setCompany?.({ ...company, address: null })
            : setCompany?.({ ...company, address: value });

    const setEmployeeCount = (value: string) => value.length == 0
            ? setCompany?.({ ...company, count_employees: null })
            : setCompany?.({ ...company, count_employees: Number(value) });

    const setCompanyPhone = (v: string) => {
        let code = companyPhoneRef.current?.state.dialCode.trim() ?? "+966";
        let company_phone = v.replaceAll(" ", "");
        let company_mobile = code + company_phone.replace(code, "");
        setCompany?.({ ...company, company_phone, company_mobile });
    }

    const setRepresentativePhone = (v: string) => {
        let code = companyPhoneRef.current?.state.dialCode.trim() ?? "+966";
        let representative_phone = v.replaceAll(" ", "");
        let representative_mobile = code + representative_phone.replace(code, "");
        setCompany?.({ ...company, representative_phone, representative_mobile });
    }

    const setAgree = (value: boolean) => setCompany?.({ ...company, "agree": value });

    return (
        <div className="row">
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="name" className="auth-form-label d-block">إسم الشركة</label>
                    <input
                        id="name"
                        className={`${!isNameValid() && 'was-validated'} auth-form-text-input`}
                        name="name"
                        type="text"
                        placeholder="أدخل إسم الشركة هنا"
                        value={company?.name}
                        onChange={(e) => setCompanyField("name", e.currentTarget.value)}
                    />
                    {
                        !isNameValid() && (
                            <p className="error-text">يجب ان يكون ما بين ال ٣ احرف وال ٥٠ حرف.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="company_specialization_id" className="auth-form-label d-block">مجال العمل</label>
                    <div className="dark-dropdown-arrow">
                        <select
                            id="company_specialization_id"
                            className={`${!isSpecializationValid() && 'was-validated'} auth-form-text-input px-4 select-hide-dropdown`}
                            name="company_specialization_id"
                            value={company?.company_specialization_id}
                            onChange={(e) => setCompanyIntField("company_specialization_id", Number(e.currentTarget.value))}
                        >
                            <option value="0">اختر المجال</option>
                            {
                                specializations.map(specialization =>
                                        (<option key={specialization.id} value={specialization.id}>{specialization?.name}</option>)
                                )
                            }
                        </select>
                    </div>
                    {
                        !isSpecializationValid() && (
                            <p className="error-text">يجب عليك اختيار مجال للشركة.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="capital" className="auth-form-label d-block">رأس المال</label>
                    <input
                        id="capital"
                        className={`${!isCapitalValid() && 'was-validated'} auth-form-text-input`}
                        name="capital" 
                        type="number"
                        placeholder="أدخل رأس المال هنا"
                        value={company?.capital?.toString() ?? ""}
                        onChange={(e) => {let val = Number.parseInt(e.currentTarget.value); if ((!isNaN(val) && val >= 0) || e.currentTarget.value.length == 0) setCompanyField("capital", val.toString())}}
                    />
                    {
                        !isCapitalValid() && (
                            <p className="error-text">يجب أن لا يقل عن ١٠٠ ر.س.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="phone" className="auth-form-label d-block">الرقم الضريبي</label>
                    <input
                        id="tax_number"
                        className={`${!isTaxNumberValid() && 'was-validated'} auth-form-text-input`}
                        name="tax_number"
                        type="number"
                        placeholder="أدخل الرقم الضريبي هنا"
                        value={company?.tax_number?.toString()}
                        onChange={(e) => {let val = Number.parseInt(e.currentTarget.value); if ((!isNaN(val) && val >= 0) || e.currentTarget.value.length == 0) setCompanyField("tax_number", val.toString())}}
                    />
                    {
                        !isTaxNumberValid() && (
                            <p className="error-text">يجب ان يكون ما بين  ٦ أرقام والى ٥٠ رقم.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="email" className="auth-form-label d-block">بريد الشركة الالكتروني</label>
                    <input
                        id="email"
                        className={`${!isCompanyEmailValid() && 'was-validated'} auth-form-text-input`}
                        name="email"
                        type="text"
                        placeholder="أدخل بريد الشركة الالكتروني هنا"
                        value={company?.email}
                        onChange={(e) => setCompanyField("email", e.currentTarget.value)}
                    />
                    {
                        !isCompanyEmailValid() && (
                            <p className="error-text">يجب كتابة البريد الالكتروني بشكل صحيح.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="address" className="auth-form-label d-block">موقع الشركة <span style={{ marginInlineStart: "0.15rem", fontSize: "0.75rem" }}>(اختياري)</span></label>
                    <input
                        id="address"
                        className={`${!isAddressValid() && 'was-validated'} auth-form-text-input`}
                        name="address"
                        type="text"
                        placeholder="أدخل موقع الشركة هنا"
                        value={company?.address ?? ""}
                        onChange={(e) => setAddress(e.currentTarget.value)}
                    />
                    {
                        !isAddressValid() && (
                            <p className="error-text">يجب كتابة ٥ احرف علي الأقل أو اتركها فارغة.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="count_employees" className="auth-form-label d-block">عدد الموظفين <span style={{ marginInlineStart: "0.15rem", fontSize: "0.75rem" }}>(اختياري)</span></label>
                    <input
                        id="count_employees"
                        className={`${!isEmployeeCountValid() && 'was-validated'} auth-form-text-input`}
                        name="count_employees"
                        type="number"
                        placeholder="أدخل عدد الموظفين هنا"
                        value={company?.count_employees?.toString() ?? ""}
                        onChange={(e) => {let val = Number.parseInt(e.currentTarget.value); if ((!isNaN(val) && val >= 0) || e.currentTarget.value.length == 0) setEmployeeCount(val.toString())}}
                    />
                    {
                        !isEmployeeCountValid() && (
                            <p className="error-text">يجب كتابة رقم اكبر من الصفر أو تركها فارغة.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <label htmlFor="company_mobile" className="auth-form-label d-block">رقم هاتف الشركة</label>
                <div className='position-relative phone-dropdown' dir='ltr'>
                    <IntlTelInput
                        ref={companyPhoneRef}
                        inputClassName={`${ !isCompanyPhoneValid() && 'was-validated'} auth-form-text-input`}
                        preferredCountries={['sa']}
                        useMobileFullscreenDropdown
                        separateDialCode
                        telInputProps={{
                            id: "company_mobile",
                            name: "company_mobile",
                            onKeyPress: (e) => e.charCode != 43 &&
                                !(e.charCode >= 48 && e.charCode <= 57)
                                && e.preventDefault()
                        }}
                        placeholder="أدخل هاتف الشركة هنا"
                        value={company?.company_phone}
                        onSelectFlag={(v) => setCompanyPhone(v)}
                        onPhoneNumberChange={(_, v) => setCompanyPhone(v)}
                    />
                </div>
                {
                    !isCompanyPhoneValid() && (
                        <p className="error-text">يجب كتابة رقم هاتف بشكل صحيح.</p>
                    )
                }
            </div>
            <div className="col-12 col-md-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="representative_name" className={`auth-form-label d-block`}>اسم الممثل</label>
                    <input
                        id="representative_name" 
                        className={`${!isRepresentativeNameValid() && 'was-validated'} auth-form-text-input`}
                        name="representative_name"
                        type="text"
                        placeholder="أدخل اسم الممثل هنا"
                        value={company?.representative_name}
                        onChange={(e) => setCompanyField("representative_name", e.target.value)}
                    />
                    {
                        !isRepresentativeNameValid() && (
                            <p className="error-text">يجب ان يكون ما بين ال ٣ احرف وال ٥٠ حرف.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <label htmlFor="representative_mobile"
                    className="auth-form-label d-block">رقم هاتف الممثل</label>
                <div className='position-relative phone-dropdown' dir='ltr'>
                    <IntlTelInput
                        ref={representativePhoneRef}
                        inputClassName={`${ !isRepresentativePhoneValid() && 'was-validated'} auth-form-text-input`}
                        preferredCountries={['sa']}
                        useMobileFullscreenDropdown
                        separateDialCode
                        telInputProps={{
                            id: "representative_mobile",
                            name: "representative_mobile",
                            onKeyPress: (e) => e.charCode != 43 &&
                                !(e.charCode >= 48 && e.charCode <= 57)
                                && e.preventDefault()
                        }}
                        placeholder="أدخل هاتف الشركة هنا"
                        value={company?.representative_phone}
                        onSelectFlag={(v) => setRepresentativePhone(v)}
                        onPhoneNumberChange={(_, v) => setRepresentativePhone(v)}
                    />
                </div>
                {
                    !isRepresentativePhoneValid() && (
                        <p className="error-text">يجب كتابة رقم هاتف بشكل صحيح.</p>
                    )
                }
            </div>
            <div className="col-12">
                <div className="auth-form-elements-group">
                    <label htmlFor="representative_email" className="auth-form-label d-block">ايميل الممثل</label>
                    <input
                        id="representative_email"
                        className={`${!isRepresentativeEmailValid() && 'was-validated'} auth-form-text-input`}
                        name="representative_email"
                        type="text"
                        placeholder="أدخل ايميل الممثل هنا"
                        value={company?.representative_email}
                        onChange={(e) => setCompanyField("representative_email", e.target.value)}
                    />
                    {
                        !isRepresentativeEmailValid() && (
                            <p className="error-text">يجب كتابة البريد الالكتروني بشكل صحيح.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12">
                <div className="auth-form-elements-group">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={company?.agree}
                            onChange={(e) => setAgree(e.currentTarget.checked)}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            بالنقر هنا فإنك توافق على <a href="/terms" target="_blank" style={{ textDecoration: "underline" }}>سياسة
                                الخصوصية</a>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step4;