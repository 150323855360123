import React from 'react';
import { FunctionComponent } from "react";
import { Link } from 'react-router-dom';

interface HeroProps {
    isAuth: boolean;
}

const Hero: FunctionComponent<HeroProps> = (props) => {

    return (
        <>
            <section id="hero" className="mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5 d-flex align-items-center content-center">
                            <div className="hero-text">
                                <h5>حقق أهدافك المالية</h5>
                                <h1>الحل الأمثل <span>لادارة التمويل</span> <br /> و التسويق بكفاءة</h1>
                                <p>
                                    اكتشف كيف يمكن لمنصتنا تحويل تجربتك المالية والتجارية إلى تجربة سلسة وفعالة. من خلال
                                    أدوات متقدمة لطلب التمويل والتسوق.
                                </p>
                                <div className="hero-cta-container">
                                    {
                                        !props.isAuth && (
                                            <>
                                                <Link to="/register?selector=provider" className="cta cta-thic me-3 hero">مورد</Link>
                                                <Link to="/register?selector=customer" className="cta cta-light border hero">عميل</Link>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="hero-avatars-container me-3">
                                        <a href="#" className="avatar">
                                            <img src="/assets/images/avatar-1.svg" alt="avatar" />
                                        </a>
                                        <a href="#" className="avatar">
                                            <img src="/assets/images/avatar-2.svg" alt="avatar" />
                                        </a>
                                        <a href="#" className="avatar">
                                            <img src="/assets/images/avatar-3.svg" alt="avatar" />
                                        </a>
                                        <a href="#" className="avatar">
                                            <img src="/assets/images/avatar-4.svg" alt="avatar" />
                                        </a>
                                        <a href="#" className="avatar">
                                            <img src="/assets/images/avatar-add.svg" alt="avatar" />
                                        </a>
                                    </div>

                                    <div className="hero-avatars-cta-text">
                                        <p className="lead mb-0">
                                            انضم الان !
                                        </p>
                                        <p className="sub">
                                            انضم الى موردين و عملاء حول المملكة
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <img src="/assets/images/hero-image.png" id="hero-img" alt="hero banner" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;