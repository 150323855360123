import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import { API_BASE_URL } from '../../constants/apiConstants';
import  Partner ,{PartnerProps } from '../../components/Partner/Partner';
// import {Partner ,PartnerProps}from '../../components/Partner/Partner';

interface PartnersProps {

}

const Partners: FunctionComponent<PartnersProps> = () => {
    const [partners, setPartners] = useState<PartnerProps[]>([]);
    useEffect(() => {
        axios.get(API_BASE_URL + '/partners')
            .then(function (response) {
                if (response.status == 200) {
                    setPartners(response.data.data.data.slice(0, 9))
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    },[]);
    return (
        <>
            <section id="partners" className="section-element my-5">
                <div className="container">
                    <div className="section-header">
                        <h6>
                            شركائنا المميزين
                        </h6>
                        <p>
                            شركائنا هم القوة الدافعة خلف نجاحنا. نقدم لك قائمة بأفضل الشركات .
                        </p>
                    </div>

                    <div className="partners-grid">
                        {
                            partners.length > 0 ? (
                                partners.map(partner => (
                                    <Partner id={partner.id} img={partner?.img } key={partner.id} />
                                ))
                            ) : (
                                new Array(9).fill(null).map((_, i) => (
                                    <div className="d-flex flex-column benefit-item border-item justify-content-center py-8" style={{ height: "172.6px" }} key={i}>
                                        <div className="skeleton-box w-25"></div>
                                        <div className="skeleton-box w-100 my-1"></div>
                                        <div className="skeleton-box w-75 mb-1"></div>
                                        <div className="skeleton-box w-50"></div>
                                    </div>
                                ))
                            )
                        }
                        <div className="benefit-item border-item partners-more-links">
                            <Link to="/partners" onClick={() => window.scrollTo(0, 0)}>و المزيد</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Partners;