import React from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import { JobInterface } from '../../pages/Jobs/Jobs';


const JobCard: FunctionComponent<JobInterface> = (props) => {
    return (
        <>
            <div className="col-12 col-lg-6 mb-3">
                <div className="single-job-item h-100">
                    <div className="single-job-top d-flex justify-content-between align-items-center">
                        <div className="single-job-top-text">
                            <p className="datetime m-1">{props.created_at}</p>
                            <h4 className="job-title m-0">
                                <b>{props.title}</b>
                            </h4>
                        </div>
                        <div className="single-job-cta">
                            <button 
                            onClick={()=>props.changeJobId(props.id)}
                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                className="cta cta-thic px-4">قدم الان</button>
                        </div>
                    </div>
                    <p className="job-description">
                        {props.content}
                    </p>
                    <div className="tags-container pt-2">
                        {props.tags?.map((v, i) => 
                            <span key={i} className="tag cta cta-light mt-2 me-1 me-md-2 d-inline-block">{v.name}</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobCard;
