import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import { Link, useParams } from "react-router-dom";
import moment from "moment-hijri";

interface PostProps {
    id?: number
    title?: string
    intro?: string;
    description?: string
    outro?: string;
    image?: string
    created_at?: string
}

interface PostProps {

}

const weekdays: {[x: string]: string} = {
    'Saturday': 'السبت',
    'Sunday': 'الأحد',
    'Monday': 'الإثنين',
    'Tuesday': 'الثلاثاء',
    'Wednesday': 'الأربعاء',
    'Thursday': 'الخميس',
    'Friday': 'الجمعة'
};

const Post: FunctionComponent<PostProps> = () => {
    const [isLoaded, setLoaded] = useState(false);
    const [post, setPost] = useState<PostProps>({});
    const { id } = useParams();

    useEffect(() => {
        axios.get(API_BASE_URL + '/articles/' + id + '/show', configAuthApi())
            .then(function (response) {
                if (response.status == 200) {
                    let data = response.data.data;

                    if (data.created_at) {
                        const hijriDate = moment(data.created_at).format('idddd iD iMMMM YYYY');
                        const weekday = moment(data.created_at).format('dddd'); 
                        data.created_at = hijriDate.replace(weekday, weekdays[weekday]);
                    }

                    setLoaded(true);
                    setPost(data);
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }, []);

    return (
        <>
            {
                isLoaded ? (
                    <section id="blog-post" className="section-element">
                        <div className="container">
                            <div className="section-header">
                                <p style={{ fontWeight: "bold" }}>
                                    <Link to="" style={{ color: "inherit" }} className="text-decoration-none">
                                        الرئيسية
                                    </Link>
                                </p>
                                <Link to="/blog" style={{ textDecoration: "none" }}>
                                    <h6>
                                        المدونة
                                    </h6>
                                </Link>
                            </div>

                            <div className="blog-body">
                                <div className="col-12 mb-5 pb-5">
                                    <div className="blogs-hero" style={{ backgroundImage: `url('${post.image}')` }}>
                                    </div>
                                </div>

                                <div className="blog-text">
                                    <h4>مقدمة</h4>
                                    <p
                                        className="mb-5"
                                        dangerouslySetInnerHTML={{ __html: post.intro ?? "" }}
                                    >
                                    </p>

                                    <h4>الوصف</h4>
                                    <p
                                        className="mb-5"
                                        dangerouslySetInnerHTML={{ __html: post.description ?? "" }}
                                    >
                                    </p>

                                    {
                                        post.outro && (
                                            <>
                                                <h4>الخاتمة</h4>
                                                <p
                                                    className="mb-5"
                                                    dangerouslySetInnerHTML={{ __html: post.outro }}
                                                >
                                                </p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </section>
                ) : (
                    <section id="blog-post" className="section-element">
                        <div className="container">
                            <div className="section-header">
                                <p style={{ fontWeight: "bold" }}>
                                    <Link to="" style={{ color: "inherit" }} className="text-decoration-none">
                                        الرئيسية
                                    </Link>
                                </p>
                                <h6>المدونة</h6>
                            </div>

                            <div className="blog-body">
                                <div className="col-12 mb-5 pb-5">
                                    <div className="blogs-hero skeleton-box" style={{ backgroundColor: "#f8f8f8" }}>
                                        <div className="blogs-hero-text">
                                            <p className="date-time">
                                                <span className="skeleton-box w-25"></span>
                                            </p>
                                            <h1 className="blogs-hero-heading">
                                                <span className="skeleton-box w-50"></span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog-text">
                                    <h4>
                                        <span className="skeleton-box w-25"></span>
                                    </h4>
                                    <p className="d-flex flex-column mb-5">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-50 mb-2"></span>
                                        <span className="skeleton-box w-25"></span>
                                    </p>

                                    <h4>
                                        <span className="skeleton-box w-25"></span>
                                    </h4>
                                    <p className="d-flex flex-column mb-5">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-50 mb-2"></span>
                                        <span className="skeleton-box w-25"></span>
                                    </p>

                                    <h4>
                                        <span className="skeleton-box w-25"></span>
                                    </h4>
                                    <p className="d-flex flex-column mb-5">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-50 mb-2"></span>
                                        <span className="skeleton-box w-25"></span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </section>
                )
            }
        </>
    );
}

export default Post;