import React from 'react';
import { FunctionComponent } from "react";

interface FeaturesProps {

}

const Features: FunctionComponent<FeaturesProps> = () => {

    return (
        <>
            <section id="revloutinary" className="section-element my-5">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-5 mb-5 mb-lg-0">
                    <img src="/assets/images/about-us-image.svg" alt="about us image" className="img-fluid"/>
                </div>
                <div className="col-12 col-lg-1"></div>
                <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                    <div className="section-header">
                        <h6>
                            مزايا تمويل مبتكرة
                        </h6>
                        <p>
                            استفد من ميزاتنا الفريدة التي تجعل التمويل أسهل وأكثر أمانًا
                        </p>
                    </div>

                    <div className="row px-2 px-md-0">
                        <div className="col-12 col-sm-6 pe-md-4 mb-lg-5 mb-5  pe-0 ">
                            <div className="benefit-item">
                                <img src="/assets/images/money-icon.svg" height="70px" width="70px" alt="icon"/>
                                <p className="benefit-heading">
                                    تمويل سريع و مرن
                                </p>
                                <p className="sub-text">
                                    استمتع بعملية تمويل بسيطة وسريعة تتيح لك تقسيم المبلغ إلى أربعة أقساط متساوية. </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 pe-md-4 mb-lg-5 mb-5  pe-0">
                            <div className="benefit-item">
                                <img src="/assets/images/partner-icon.svg" height="70px" width="70px" alt="icon"/>
                                <p className="benefit-heading">
                                    شركاء موثوقون
                                </p>
                                <p className="sub-text">
                                    التطبيق يتعاون مع مجموعة واسعة من المتاجر والشركاء الموثوقين.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 pe-md-4 mb-lg-5 mb-5  pe-0">
                            <div className="benefit-item">
                                <img src="/assets/images/shield-icon.svg" height="70px" width="70px" alt="icon"/>
                                <p className="benefit-heading">
                                    أمان وخصوصية
                                </p>
                                <p className="sub-text">
                                    يتم تأمين جميع بياناتك الشخصية والمصرفية باستخدام أحدث تقنيات التشفير.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 pe-md-4 mb-lg-5 mb-5  pe-0">
                            <div className="benefit-item">
                                <img src="/assets/images/gauge-icon.svg" height="70px" width="70px" alt="icon"/>
                                <p className="benefit-heading">
                                    إدارة حساب سلسة
                                </p>
                                <p className="sub-text">
                                    يمكنك بسهولة إدارة حسابك، رفع الفواتير، وتتبع عمليات الدفع.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
}

export default Features;