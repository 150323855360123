class validate {

    static Email(email: string)  {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }
    static MobileNumber(phone:string):boolean{
        const sanitizedPhone = phone.replace(/[\s-()]/g, '');
        const regexp = /^\+?[1-9]\d{1,14}$/;
        return regexp.test(sanitizedPhone);
    }
    static Password(input: string,len=6): boolean {
        if (!input) {
            return false; // Required field validation failed
        }
        if (input.length < len) {
            return false; // Minimum length validation failed
        }
        const regexp = /^[a-zA-Z0-9]+$/; // Only letters and digits allowed, adjust as needed
        return regexp.test(input)
    }
}
export default validate;
