import React from 'react';
import { FunctionComponent } from "react";

interface FundsProps {

}

const Funds: FunctionComponent<FundsProps> = () => {

    return (
        <>
            <section id="funds" className="section-element mb-4">
                <div className="container">
                    <div className="section-header">
                        <h6>
                            قدّم طلب التمويل
                        </h6>
                        <p>
                            احصل على التمويل الذي تحتاجه بسهولة وسرعة من خلال طلب التمويل عبر منصتنا.
                        </p>
                    </div>

                    <div className="row text-center text-md-start">
                        <div className="col-12 col-lg-3 mb-2 mb-lg-0 ">
                            <div className="benefit-item border-item" style={{ height: "100%" }}>
                                <img src="/assets/images/users-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    ابحث عن الموردين
                                </p>
                                <p className="sub-text">
                                    قم بالبحث عن الموردين الشركاء المتاحين عبر منصتنا باستخدام رقم الفيندور أو اسم المورد،
                                    واستعرض تفاصيلهم لتجد الأنسب لاحتياجاتك.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                            <div className="benefit-item border-item" style={{ height: "100%" }}>
                                <img src="/assets/images/cash-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    قدم طلب التمويل
                                </p>
                                <p className="sub-text">
                                    اختر المورد المفضل وقدم طلب تمويل عبر المنصة بسهولة. قم بملء التفاصيل المطلوبة مثل مبلغ
                                    التمويل والغرض من استخدامه. </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                            <div className="benefit-item border-item" style={{ height: "100%" }}>
                                <img src="/assets/images/verified-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    توثيق الحساب
                                </p>
                                <p className="sub-text">
                                    عبر رفع المستندات المطلوبة مثل القوائم المالية والمستندات القانونية. تأكد من إرفاق جميع
                                    الوثائق لضمان سرعة الموافقة على التمويل.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                            <div className="benefit-item border-item" style={{ height: "100%" }}>
                                <img src="/assets/images/wallet-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    ادفع على أقساط
                                </p>
                                <p className="sub-text">
                                    بعد الموافقة على طلب التمويل، ابدأ التسوق من الموردين الشركاء واستفد من خيار الدفع على أقساط
                                    مريحة لتسديد المبلغ.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Funds;