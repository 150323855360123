import React, { useState } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import SideAuth from '../../components/SideAuth/SideAuth';
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import validate from '../../utils/validation';

interface LoginProps {

}

const Login: FunctionComponent<LoginProps> = () => {
    const [isLoading, setLoading] = useState(false);
    const [Errors, setErrors] = useState<string[]>([]);
    const [isEmailValid, setEmailValid] = useState(true);
    const [isPasswordValid, setPasswordValid] = useState(true);
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const loginApi = () => {
        setLoading(true);

        axios.post(`${API_BASE_URL}/auth/login`, { email, password, platform: "web" })
            .then(function (response) {
                if (response.status == 200) {
                    localStorage.setItem('jwt-token', response.data.data.token);
                    setErrors([]);

                    if (localStorage.getItem("job-id-redirect")) {
                        window.location.href = "/jobs";
                    } else {
                        window.location.href = "/";
                    }
                }
            })
            .catch(function (error) {
                let new_errors: string[] = [];

                if (error.response.status == 422) {
                    let errors = error.response.data.errors;
                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                    });
                }

                if (error.response.status == 400) {
                    new_errors.push(error.response.data.message);
                }

                setErrors(new_errors);
            })
            .finally(function () {
                setLoading(false);
            });
    }

    return (
        <>
            {
                isLoading && (
                    <div className="preloader">
                        <div className="loader"></div>
                    </div>
                )
            }
            <div className="full-height">
                <div className="row auth-container">
                    <div className="col-12 col-lg-6 auth-columns">
                        <div className="full-height auth-form-column">
                            <div>
                                <a href="/">
                                    <img src="/assets/images/logo.svg" alt="logo" className="mb-5" />
                                </a>
                                <div className="auth-form-text mt-md-3" style={{ marginBottom: "4rem" }}>
                                    <h1>مرحباً بك في منصة التمويل</h1>
                                    <p>
                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى.
                                    </p>
                                </div>
                            </div>
                            <div className="auth-form-inputs">
                                {
                                    Errors.length > 0 && (
                                        <div style={{ marginBottom: "2rem" }}>
                                            {
                                                Errors.map((e, i) =>
                                                    (
                                                        <div className="alert alert-danger" style={{ marginBottom: "0.5rem" }} key={i}>
                                                            خطأ: { e }
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                }
                                <div className="auth-form-elements-group">
                                    <label htmlFor="email" className="auth-form-label">البريد الالكتروني</label>
                                    <input type="email" name="email" id="email" className={`auth-form-text-input mb-2 ${!isEmailValid && 'was-validated'}`}
                                        placeholder="اكتب بريدك الالكتروني هنا"
                                        value={email}
                                        onChange={(e) => {setEmail(e.target.value); setEmailValid(validate.Email(e.target.value))}}
                                    />
                                    {
                                        !isEmailValid && (
                                            <p className="error-text">يجب كتابة البريد الالكتروني بشكل صحيح.</p>
                                        )
                                    }
                                </div>
                                <div className="auth-form-elements-group mb-4">
                                    <label htmlFor="email" className="auth-form-label">كلمة المرور</label>
                                    <div className="position-relative mb-2">
                                        <input type={isPasswordShow ? "text" : "password"} name="password" id="password" className={`auth-form-text-input ${!isPasswordValid && 'was-validated'}`}
                                            placeholder="اكتب كلة المرور هنا"
                                            value={password}
                                            onChange={(e) => {setPassword(e.target.value); setPasswordValid(validate.Password(e.target.value))}}
                                        />
                                        <button className="show-hide-password-btn" onClick={() => setPasswordShow(!isPasswordShow)}>
                                        {
                                            isPasswordShow
                                                ? (
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={1.5} stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>
                                                ) : (
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path d="M14.83 9.17999C14.2706 8.61995 13.5576 8.23846 12.7813 8.08386C12.0049 7.92926 11.2002 8.00851 10.4689 8.31152C9.73758 8.61453 9.11264 9.12769 8.67316 9.78607C8.23367 10.4444 7.99938 11.2184 8 12.01C7.99916 13.0663 8.41619 14.08 9.16004 14.83" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M12 16.01C13.0609 16.01 14.0783 15.5886 14.8284 14.8384C15.5786 14.0883 16 13.0709 16 12.01" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M17.61 6.39004L6.38 17.62C4.6208 15.9966 3.14099 14.0944 2 11.99C6.71 3.76002 12.44 1.89004 17.61 6.39004Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M20.9994 3L17.6094 6.39" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M6.38 17.62L3 21" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M19.5695 8.42999C20.4801 9.55186 21.2931 10.7496 21.9995 12.01C17.9995 19.01 13.2695 21.4 8.76953 19.23" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </g>
                                                    </svg>
                                                )
                                        }
                                    </button>
                                    </div>
                                    {
                                        !isPasswordValid && (
                                            <p className="error-text">يجب كتابة ٦ احرف علي الأقل وان لا يحتوي الا علي حروف وارقام.</p>
                                        )
                                    }
                                </div>
                                <Link to="/forgot-password" className="auth-form-anchors">نسيت كلمة المرور</Link>
                            </div>
                            <div style={{ paddingBottom: "4rem" }}>
                                <div className="auth-form-submit-button-container mb-3">
                                    <button type="submit" className={`auth-form-submit-button ${ !(isEmailValid && isPasswordValid) && "disabled" }`} onClick={() => loginApi()} disabled={!(isEmailValid && isPasswordValid)}>تسجيل الدخول</button>
                                </div>
                                <div className="create-account-container text-center">
                                    <span>ليس لديك حساب ؟</span>
                                    <Link to="/register" className="auth-form-anchors font-bold">إنشاء حساب</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SideAuth />
                </div >
            </div>
        </>
    );
}

export default Login;