export const API_BASE_URL = "http://localhost/api";
export const configAuthApi = function () {
    return {
        headers:  { 
            Authorization: Boolean(localStorage.getItem('jwt-token'))
                ? `Bearer ${localStorage.getItem('jwt-token')}`
                : undefined,
            Accept: "application/json"
        }
    }
};

export const firebaseConfig = {
    apiKey: "AIzaSyByB8lZpC5e9gPry0pwFYt1gmqOIaUDqJQ",
    authDomain: "firstpay-2c1b4.firebaseapp.com",
    projectId: "firstpay-2c1b4",
    storageBucket: "firstpay-2c1b4.appspot.com",
    messagingSenderId: "702443443470",
    appId: "1:702443443470:web:1cf5f004eb5d7b56bc735f",
    measurementId: "G-8H1HR70KR7"
};