import React, { useEffect } from 'react';
import { FunctionComponent } from "react";
import { useOutletContext } from "react-router-dom";
import Partners from '../../containers/Partners/Partners';
import Hero from '../../containers/Hero/Hero';
import Benefits from '../../containers/Benefits/Benefits';
import Download from '../../containers/Download/Download';
import Funds from '../../containers/Funds/Funds';
import Faqs from '../../containers/Faqs/Faqs';
import Contact from '../../containers/Contact/Contact';
import Application from '../../containers/Application/Application';

interface HomeProps {

}

const Home: FunctionComponent<HomeProps> = () => {
    // useEffect(()=>{
    //     axios.post("http://coonex.xyz/api/register",{
    //         "name"      : "company_name",
    //         "email"     : "company@company.com",
    //         "password"  : "123456789" ,
    //         "business_name":"business_name",
    //         "valid_to":"3020-12-12",
    //         "domin":"my.coonex.localhost"
    //     }).then(r=>{
    //         console.log(r)
    //     }).catch(e=>{
    //         console.log(e)
    //     })
    // })

    const [isAuth]: boolean[] = useOutletContext();

    return (
        <>
            <Hero isAuth={isAuth} />
            <Benefits />
            <Download />
            <Funds />
            <Contact />
            <Partners />
            <Faqs />
            <Application />
        </>
    );
}

export default Home;
