import React from 'react';
import { FunctionComponent } from "react";

interface ApplicationProps {

}

const Application: FunctionComponent<ApplicationProps> = () => {

    return (
        <>
            <div className="container section-element mb-4" id="journey">
                <section className="row primary-bg">
                    <div className="col-12 col-lg-5">
                        <div className="primary-section-text">
                            <h2>ابدأ رحلتك المالية الذكية!</h2>
                            <p>
                                استفد من أحدث الحلول التمويلية وإدارة الحسابات بكل سهولة. قم بتحميل تطبيقنا اليوم وتمتع بخدمات
                                سريعة
                                وآمنة، وابدأ في إدارة تمويلك وتواصل مع الموردين بأفضل الطرق المتاحة.
                            </p>
                            <div className="download-links-container">
                                <a href="#">
                                    <img src="/assets/images/google-play.svg" width="200px" alt="app store logo" />
                                </a>
                                <a href="#">
                                    <img src="/assets/images/app-store.svg" width="200px" alt="google play logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 position-relative">
                        <img src="/assets/images/donload-links-image.svg " alt="image" className="img-fluid download-links-image" />
                    </div>
                </section>
            </div>
        </>
    );
}

export default Application;