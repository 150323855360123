import React from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { BlogProps } from '../../pages/Blog/Blog';


const BlogComponent: FunctionComponent<BlogProps> = (props) => {
    return (
        <>
            <div className="col-12 col-md-6 col-lg-4 mb-4 px-lg-4">
                <div className="single-blog-card" style={{ width: "100%" }}>
                    <img src={props.image} alt="blog article image" className="img-fluid" style={{ height: "16.5rem" }} />
                    <div className="blog-item-text">
                        <p className="m-0 blog-item-date-time">{props.created_at}</p>
                        <h4 className="blog-item-heading m-0"><Link to={`/blog/${props.id}`} onClick={() => window.scrollTo(0, 0)}>{props.title}</Link></h4>
                        <p className="blog-item-description"
                            dangerouslySetInnerHTML={{ __html: props.description ?? "" }}
                        ></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogComponent;
