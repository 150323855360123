import React, { useState } from 'react';
import { FunctionComponent } from "react";
import SideAuth from '../../components/SideAuth/SideAuth';

interface OtpProps {

}
const Otp: FunctionComponent<OtpProps> = () => {
    const [otp, setOtp] = useState('');
    return (
        <>
        <div className="full-height">

            <div className="row auth-container">
                <div className="col-12 col-lg-6 auth-columns">
                    <div className="auth-form-column">
                        <img src="/assets/images/logo.svg" alt="logo" className="mb-5" />
                        <div className="auth-form-text">
                            <h1>مرحباً بك في منصة التمويل</h1>
                            <p>
                                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى.
                            </p>
                        </div>
                        <div className="auth-form-inputs">
                            <div className="auth-form-elements-group" dir="ltr">
                                <div id="otp-input">
                                    <input type="number" step="1" min="0" max="9" pattern="\d*" />
                                    <input type="number" step="1" min="0" max="9" pattern="\d*" />
                                    <input type="number" step="1" min="0" max="9" pattern="\d*" />
                                    <input type="number" step="1" min="0" max="9" pattern="\d*" />
                                </div>
                                <input type="hidden" name="otp" />
                            </div>
                            <div className="auth-form-submit-button-container mb-3">
                                <button type="submit" className="auth-form-submit-button">تسجيل الدخول</button>
                            </div>
                            <div className="create-account-container text-center">
                                <span>لم يتم ارسال الكود ؟</span>
                                <a href="#" className="auth-form-anchors font-bold">إعادة ارسال</a>
                            </div>
                        </div>
                    </div>
                </div>
                <SideAuth />
            </div>
        </div>

        </>
    );
}

export default Otp;