import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-intl-tel-input/dist/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserLayout from './pages/Layout/User/Layout';
import GuestLayout from './pages/Layout/Guest/Layout';
import NoPage from './pages/NOPage/NoPage';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import About from './pages/About/About';
import Faq from './pages/Faq/Faq';
import Otp from './pages/Otp/Otp';
import Register from './pages/Register/Register';
import Blog from './pages/Blog/Blog';
import ContactUs from './pages/ContactUs/ContactUs';
import Jobs from './pages/Jobs/Jobs';
import Terms from './pages/Terms/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Post from './pages/Post/Post';
import Partners from './pages/Partners/Partners';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import axios from 'axios';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

axios.interceptors.request.use(function (config) {
  config.headers.set("Accept-Language", "ar");
  return config;
});

root.render(
  <React.StrictMode>

    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="otp" element={<Otp />} />
        <Route path="register" element={<Register />} />
        <Route path="/" element={<GuestLayout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<Faq />} />
          <Route path="partners" element={<Partners />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<Post />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
