import React, { useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";

interface QuestionProps {
    id?: number
    question?: string
    answer?: string
}

const Question: FunctionComponent<QuestionProps> = (props) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {
                props.id ? (
                    <div className="accordion-item mb-4" style={{ cursor: "pointer" }} onClick={() => show === true && setShow(false)}>
                        <h2 className="accordion-header" onClick={() => setShow(!show)} id={`heading-${props.id}`}>
                            <button
                                className={`accordion-button ${ show ? "pb-2" : "collapsed" }`}
                                type="button"
                            >
                                {props.question}
                            </button>
                        </h2>
                        <div className={`accordion-collapse collapse ${show ? "show" : ""}`}>
                            <div className="accordion-body pt-2">
                                {props.answer}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="accordion-item mb-4" style={{ cursor: "pointer" }} onClick={() => show === true && setShow(false)}>
                        <h2 className="accordion-header" onClick={() => setShow(!show)} id={`heading-${props.id}`}>
                            <button
                                className={`accordion-button ${ show ? "pb-2" : "collapsed" }`}
                                type="button"
                            >
                                <span className="skeleton-box w-25"></span>
                            </button>
                        </h2>
                        <div className={`accordion-collapse collapse ${show ? "show" : ""}`}>
                            <div className="accordion-body pt-2">
                                <span className="skeleton-box w-100 mb-2"></span>
                                <span className="skeleton-box w-75"></span>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default Question;
export type { QuestionProps };
