import React from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";

interface PartnerProps {
    id:number
    key:number
    img:string
    name?:string
}

const Partner: FunctionComponent<PartnerProps> = (props) => {
    return (
        <>
            <div className="d-flex justify-content-center benefit-item border-item">
                <img
                    className='img-fluid img-partner'
                    src={props.img}
                    alt="icon"
                />
            </div>
        </>
    );
}

export default  Partner ;
export type { PartnerProps };
