import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import Question, { QuestionProps } from '../../components/Question/Question';
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';

interface FaqsProps {

}

const Faqs: FunctionComponent<FaqsProps> = () => {
    const [questions,setQuestions] = useState<QuestionProps[]>([]);

    useEffect(() => {
        axios.get(API_BASE_URL + '/questions?type=web', configAuthApi())
            .then(function (response) {
                if (response.status == 200) {             
                    setQuestions(response.data.data.data.slice(0, 5));
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    },[]);

    return (
        <>
            <section id="faqs" className="section-element mb-4">
                <div className="container">
                    <div className="section-header">
                        <h6>
                            الأسئلة الشائعة
                        </h6>
                        <p>
                            أجبنا على أبرز الأسئلة التي قد تكون لديك حول خدماتنا وتطبيقنا.
                        </p>
                    </div>
                    <div className="accordion-container mb-5">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="questions-list">
                                {
                                    questions.length > 0 ? (
                                        questions?.map(v => (
                                            <Question key={v.id} id={v.id} question={v.question} answer={v.answer} />
                                        ))
                                    ) : (
                                        new Array(5).fill(null).map((_, i) => (
                                            <Question key={i} />
                                        ))
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faqs;