import React, { useContext } from 'react';
import { FunctionComponent } from "react";
import { RegisterContext } from '../../pages/Register/Register';

const Step3: FunctionComponent = () => {
    const { company, setCompany, validators } = useContext(RegisterContext);

    const isCommercialRegisterValid = () => (
        !company?.commercial_register ||
        company.commercial_register.length == 0 ||
        validators?.company.commercial_register()
    );

    const setCommercialRegister = (commercial_register: string) => setCompany?.({ ...company, commercial_register });

    return (
        <div className="row">
            <div className="col-12">
                <div className="auth-form-elements-group">
                    <label htmlFor="commercial_register" className="auth-form-label">رقم السجل التجاري</label>
                    <input
                        id="commercial_register"
                        className={`${ !isCommercialRegisterValid() && "was-validated" } auth-form-text-input`}
                        name="commercial_register"
                        type="number"
                        placeholder="أدخل رقم السجل التجاري هنا"
                        value={company?.commercial_register?.toString() ?? ""}
                        onChange={(e) => {let val = Number.parseInt(e.currentTarget.value); if ((!isNaN(val) && val >= 0) || e.currentTarget.value.length == 0) setCommercialRegister(val.toString())}}
                    />
                    {
                        !isCommercialRegisterValid() && (
                            <p className="error-text">يجب ان يكون ما بين  ٦ أرقام والى ٥٠ رقم.</p>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Step3;