import React from 'react';
import { FunctionComponent } from "react";
import { Link } from 'react-router-dom';

interface ContactProps {
    isAboutPage?: boolean
}

const Contact: FunctionComponent<ContactProps> = (props) => {

    return (
        <>
            <section id="contact" className="section-element mb-5">
                <div className="container">
                    {
                        props.isAboutPage && (
                            <div className="section-header">
                                <p style={{fontWeight: "bold"}}>
                                    <a href="#" style={{color: "inherit"}} className="text-decoration-none">
                                        الرئيسية
                                    </a>
                                </p>
                                <h6>من نحن</h6>
                            </div>
                        )
                    }
                    <div className="row">
                        <div className="col-12 col-lg-6 d-flex align-items-center px-2 px-md-5">
                            <div className="section-text text-center text-md-start">
                                <h3 className="pe-0 pe-md-5">
                                    منصة التمويل الذكية: <span>حلول مالية</span> مريحة لشركائك التجاريين
                                </h3>
                                <p>
                                    استفد من منصتنا لتبسيط عملية التمويل لمشترياتك من الموردين الشركاء. نقدم لك حلولًا مرنة
                                    وآمنة لتسهيل عمليات الدفع، مع خيارات متعددة مثل الدفع على أقساط متساوية، مما يجعل تمويل
                                    أعمالك أسهل وأسرع من أي وقت مضى.
                                </p>
                                <div className="cta-container my-5">
                                    <Link to="/contact-us" className="cta cta-thic" onClick={() => window.scrollTo(0, 0)}>
                                        تواصل معنا
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img src="/assets/images/contact-section-banner.svg" alt="download image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;