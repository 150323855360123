import React, { useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import SideAuth from '../../components/SideAuth/SideAuth';
import Contact from '../../containers/Contact/Contact';
import Funds from '../../containers/Funds/Funds';
import Features from '../../containers/Features/Features';
import Partners from '../../containers/Partners/Partners';
import Application from '../../containers/Application/Application';
import Foundations from '../../containers/Foundations/Foundations';

interface AboutProps {

}
const About: FunctionComponent<AboutProps> = () => {
    return (
        <>
            <Contact isAboutPage />
            <Foundations />
            <Features />
            <Partners />
            <Application />
        </>
    );
}

export default About;