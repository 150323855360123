import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import validate from '../../utils/validation';

interface ContactUsProps {

}
interface TypeInterface {
    id: number
    name: string
}

const ContactUs: FunctionComponent<ContactUsProps> = () => {
    const [email, setEmail] = useState("");
    const [types, setTypes] = useState<TypeInterface[]>([]);
    const [type, setType] = useState("");
    const [description, setDescription] = useState("");
    const [isSuccess, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const input_names = ["email", "number", "min_15"];
    const [validationList, setValidationList] = useState([
        true,
        true,
        true,
    ]);

    // type-problems
    // support-web
    useEffect(() => {
        axios.get(`${API_BASE_URL}/type-problems`)
            .then(r => {
                if (r.status == 200) {
                    setTypes(r.data.data)
                }
            })
            .catch(function (error) {
                console.error(error)
            });
    }, [])

    const saveData = () => {
        if (isValid) {
            setLoading(true);

            let data = {
                email,
                description,
                type_id: type
            }

            axios.post(`${API_BASE_URL}/support-web`, data, configAuthApi())
                .then(r => {
                    if (r.status == 200 || r.status == 201) {
                        setSuccess(true);
                        setIsValid(false);
                        setDescription("")
                    }
                })
                .catch(function (error) {
                    console.error(error)
                })
                .finally(function () {
                    setLoading(false);
                });
        }
    }

    const setValied = (index: number, value: boolean) => {
        let listTemp = validationList
        listTemp[index] = value
        setValidationList(listTemp)
    }

    const check = (type_validation: string, index: number, value: string) => {
        let listTemp = validationList
        switch (type_validation) {
            case input_names[0]:
                listTemp[index] = validate.Email(value)
                setValidationList(listTemp)
                break;
            case input_names[1]:
                listTemp[index] = parseInt(value) >= 0
                setValidationList(listTemp)
                break;
            case input_names[2]:
                listTemp[index] = value.length >= 15
                setValidationList(listTemp)

                break;
            default:
                break;
        }
        return listTemp[index]
    }

    const setText = (name: string, type_validation: string, index: number, value: string) => {
        let valied = check(type_validation, index, value)
        switch (name) {
            case "email":
                setEmail(value)
                break;
            case "type":
                setType(value)
                break;
            case "description":
                setDescription(value)
                break;
            default:
                break;
        }
        let temp = validationList
        temp[index] = valied
        let isFormValid = true
        validationList.forEach(v => {
            isFormValid = isFormValid && valied;
        })

        isFormValid = isFormValid && (type !== "" && description !== "" && email != "");
        setIsValid(isFormValid)

    }

    return (
        <>
            {
                isLoading && (
                    <div className="preloader">
                        <div className="loader"></div>
                    </div>
                )
            }
            {
                isSuccess && (
                    <div className="success" onClick={() => setSuccess(false)}>
                        <div className="icon">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <p>تم ارسال الرسالة بنجاح</p>
                        <p className="close-text">انقر علي الشاشة لمتابعة التصفح</p>
                    </div>
                )
            }
            <section id="contact" className="section-element">
                <div className="container">
                    <div className="section-header">
                        <p style={{ fontWeight: "bold" }}>
                            <a href="#" style={{ color: "inherit" }} className="text-decoration-none">
                                الرئيسية
                            </a>
                        </p>
                        <h6>تواصل معنا</h6>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6 p-0 p-lg-1">
                            <div className="primary-bg d-flex align-items-center h-100">
                                <div className="contact-info-container">
                                    <div className="contact-info-element">
                                        <img src="/assets/images/location-icon.svg" alt="location icon" width="80px" />
                                        <div className="contact-info-element-text">
                                            <p className="lead mb-0">
                                                مقر شركتنا
                                            </p>
                                            <p className="mb-0">
                                                Zone 70, Street 3056, Building 36،, Smisma , Saudi arabia
                                            </p>
                                        </div>
                                    </div>
                                    <div className="contact-info-element">
                                        <img src="/assets/images/phone-light-icon.svg" alt="location icon" width="80px" />
                                        <div className="contact-info-element-text">
                                            <p className="lead mb-0">
                                                رقم الهاتف
                                            </p>
                                            <p dir="ltr" className="mb-0">
                                                +966 56 765 5356
                                            </p>
                                        </div>
                                    </div>
                                    <div className="contact-info-element">
                                        <img src="/assets/images/message-light-icon.svg" alt="location icon" width="80px" />
                                        <div className="contact-info-element-text">
                                            <p className="lead mb-0">
                                                الايميل
                                            </p>
                                            <p className="mb-0">
                                                firstpay@contact.com
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 px-3 mt-4 mt-md-0">
                            <div id="contact-form">
                                <h5>نسعد بتواصلك معنا!</h5>
                                <div className="auth-form-elements-group">
                                    <label htmlFor="email" className={`auth-form-label`}>البريد الالكتروني</label>
                                    <input type="email" name="email" id="email"
                                        onBlur={(e) => check(input_names[0], 0, e.target.value)}
                                        onChange={(e) => setText("email", input_names[0], 0, e.target.value)}
                                        onFocus={() => { setValied(0, true) }}
                                        className={`${!validationList[0] && 'was-validated'} auth-form-text-input`} value={email}
                                        placeholder="أدخل البريد الالكتروني هنا" />
                                    {
                                        !validationList[0] && (
                                            <p className="error-text">يجب كتابة البريد الألكتروني بشكل صحيح.</p>
                                        )
                                    }
                                </div>
                                <div className="auth-form-elements-group">
                                    <label htmlFor="type" className={` auth-form-label d-block`}>ماذا تحتاج &#x2E2E;</label>
                                    <select name="type_id" id="type"
                                        onBlur={(e) => check(input_names[1], 1, e.target.value)}
                                        onChange={(e) => setText("type", input_names[1], 1, e.target.value)}
                                        onFocus={() => { setValied(1, true) }}
                                        className={`${!validationList[1] && 'was-validated'} auth-form-text-input px-4`}
                                        style={{ borderLeft: "1.5rem solid transparent" }}
                                        value={type}>
                                        <option value="">لدي مشكلة في المنصة</option>
                                        {types.map(t => (<option value={t.id}>{t.name}</option>))}
                                    </select>
                                    {
                                        !validationList[1] && (
                                            <p className="error-text">يجب اختيار نوع المشكلة.</p>
                                        )
                                    }
                                </div>
                                <div className="auth-form-elements-group">
                                    <label htmlFor="description" className="auth-form-label">الرسالة</label>
                                    <textarea name="description" className={`${!validationList[2] && 'was-validated'} auth-form-text-input`} style={{ borderRadius: "15px" }}
                                        onBlur={(e) => check(input_names[2], 2, e.currentTarget.value)}
                                        onChange={(e) => setText("description", input_names[2], 2, e.target.value)}
                                        onFocus={() => { setValied(2, true) }}
                                        id="description"
                                        rows={7}
                                        placeholder="أدخل رسالتك هنا"
                                        value={description}
                                        >
                                            {description}
                                    </textarea>
                                    {
                                        !validationList[2] && (
                                            <p className="error-text">يجب كتابة ١٥ حرف علي الأقل.</p>
                                        )
                                    }
                                </div>
                                <div className="auth-form-submit-button-container my-0">
                                    <button onClick={() => { saveData() }} type="button" className={`auth-form-submit-button ${!isValid && 'disabled'}`}>ارسال</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactUs;