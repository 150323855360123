import React, { useContext } from 'react';
import { FunctionComponent } from "react";
import { RegisterContext } from '../../pages/Register/Register';

const Step1: FunctionComponent = () => {
    const { user, setUser } = useContext(RegisterContext);

    const is_an = (type: "provider"|"customer") => user?.type == type;
    const set_type = (type: "provider"|"customer") => setUser?.({ ...user, type });

    return (
        <div>
            <div className="auth-form-elements-group">
                <label
                    className={`radio-label ${ is_an("provider") && "active-register-box" }`}
                    onClick={() => set_type("provider")}
                >
                    <img src={`/assets/images/case-icon${ is_an("provider") ? "-active" : "" }.svg`} alt="case icon"/>                   
                    <span>
                        <p className="lead m-0">
                            مورد
                        </p>
                        <p className="p-0">
                            إذا كنت تقدم منتجات أو خدمات وترغب في توسيع قاعدة عملائك وزيادة مبيعاتك
                        </p>
                    </span>
                </label>
            </div>
            <div className="auth-form-elements-group">
                <label
                    className={`radio-label ${ is_an("customer") && "active-register-box" }`}
                    onClick={() => set_type("customer")}
                >
                    <img src={`/assets/images/client-icon${ is_an("customer") ? "-active" : "" }.svg`} alt="case icon"/>                   
                    <span>
                        <p className="lead m-0">
                            عميل
                        </p>
                        <p className="p-0">
                            إذا كنت تبحث عن حل مرن وسهل لشراء المنتجات التي تحتاجها لتطوير أعمالك
                        </p>
                    </span>
                </label>
            </div>
        </div>
    );
}

export default Step1;