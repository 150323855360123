import React, { useState, ChangeEvent, KeyboardEvent, useEffect, useContext } from 'react';
import { FunctionComponent } from "react";
import { RegisterContext } from '../../pages/Register/Register';

const Step5: FunctionComponent = () => {
    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const { otp, setOtp } = useContext(RegisterContext);
    
    const onUnLoadEvent = (e: BeforeUnloadEvent) => {
        e.returnValue = "Are you sure you want to leave?";
    }

    useEffect(() => {
        window.addEventListener('beforeunload', onUnLoadEvent);

        return () => {
            window.removeEventListener('beforeunload', onUnLoadEvent);
        }
    }, []);

    const onChangeEvent = (i: number, e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        let e_code = e.target.value;

        if (i < 0 || i > 5) return;

        let new_code = Array.from(code);
        
        if (e_code.length <= 1) {
            new_code[i] = e_code;

            if (e_code.length > 0 && i < 5) {
                let n_elem: any = document.querySelector(`#otp-${ i + 1 }`);
                n_elem?.focus();
            }
        } else {
            let len = e_code.length;

            for (let j = 0; j < len; j++) {
                let k = i + j;

                if (k <= 5) {
                    new_code[k] = e_code[j];
                }                
            }

            let n_elem: any = document.querySelector(`#otp-${ Math.min(i + len, 5) }`);
            n_elem?.focus();
        }

        setCode(new_code);
        setOtp?.({ otp_code: new_code.join("") });

        if (i == 5 && new_code[i].length > 0) {
            let btn: any = document.querySelector("button[type=submit]");
            btn?.click();
        }
    }

    const onKeyboardDownEvent = (i: number, e: KeyboardEvent<HTMLInputElement>) => {
        if (i < 0 || i > 5) return;

        if (e.code == "ArrowUp" || e.code == "ArrowDown") {
            e.preventDefault();
        }

        if (e.code == "Backspace") {
            if (code[i].length == 0) {
                e.preventDefault();
                let elem: any = document.querySelector(`#otp-${ i - 1 }`);
                elem?.focus();
            } else {
                let new_code = Array.from(code);
                new_code[i] = "";
                setCode(new_code);
            }
        }

        if (e.code == "Delete" && i < 5) {
            if (code[i + 1].length == 0) {
                e.preventDefault();
                let elem: any = document.querySelector(`#otp-${ i + 1 }`);
                elem?.focus();
            } else {
                let new_code = Array.from(code);
                new_code[i + 1] = "";
                setCode(new_code);
                let elem: any = document.querySelector(`#otp-${ i + 1 }`);
                elem?.focus();
            }
        }

        if (e.code == "ArrowLeft") {
            e.preventDefault();
            let elem: any = document.querySelector(`#otp-${ i - 1 }`);
            elem?.focus();
        }

        if (e.code == "ArrowRight") {
            e.preventDefault();
            let elem: any = document.querySelector(`#otp-${ i + 1 }`);
            elem?.focus();
        }
    }

    const loadValue = (i: number) => code[i] == "" ? "" : Number(code[i]);

    return (
        <div className="auth-form-inputs" style={{ marginTop: "7rem", marginBottom: "7rem" }}>
            <div className="auth-form-elements-group" dir="ltr">
                <div id="otp-input">
                    <input id="otp-0" type="number" step={1} min={0} max={9} value={loadValue(0)} onChange={(e) => onChangeEvent(0, e)} onKeyDown={(e) => onKeyboardDownEvent(0, e)} autoFocus autoComplete="one-time-code" />
                    <input id="otp-1" type="number" step={1} min={0} max={9} value={loadValue(1)} onChange={(e) => onChangeEvent(1, e)} onKeyDown={(e) => onKeyboardDownEvent(1, e)} />
                    <input id="otp-2" type="number" step={1} min={0} max={9} value={loadValue(2)} onChange={(e) => onChangeEvent(2, e)} onKeyDown={(e) => onKeyboardDownEvent(2, e)} />
                    <input id="otp-3" type="number" step={1} min={0} max={9} value={loadValue(3)} onChange={(e) => onChangeEvent(3, e)} onKeyDown={(e) => onKeyboardDownEvent(3, e)} />
                    <input id="otp-4" type="number" step={1} min={0} max={9} value={loadValue(4)} onChange={(e) => onChangeEvent(4, e)} onKeyDown={(e) => onKeyboardDownEvent(4, e)} />
                    <input id="otp-5" type="number" step={1} min={0} max={9} value={loadValue(5)} onChange={(e) => onChangeEvent(5, e)} onKeyDown={(e) => onKeyboardDownEvent(5, e)} />
                </div>
                <div className="create-account-container text-center mt-5">
                    <span>لم يتم ارسال الكود ؟ </span>
                    <a href="#" className="auth-form-anchors font-bold">إعادة ارسال</a>
                </div>
            </div>
        </div>
    );
}

export default Step5;