import React, { ChangeEvent, useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import validate from '../../utils/validation';
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';

interface ModalProps{
    id:number
    active:boolean
    changeActiveModal:any
}
const JobModal: FunctionComponent<ModalProps> = (props) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [file, setFile] = useState<File|null>(null);
    const [Errors, setErrors] = useState<string[]>([]);
    const [isDone, setDone] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isNameValid, setNameValid] = useState(true);
    const [isPhoneValid, setPhoneValid] = useState(true);
    const [isEmailValid, setEmailValid] = useState(true);

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        let is_valid = val.length >= 5;

        setName(val);
        setNameValid(is_valid);

        
        if (
            is_valid &&
            isPhoneValid &&
            isEmailValid &&
            val.length > 0 &&
            phone.length > 0 &&
            email.length > 0 &&
            file
        ) {
            setDone(true);
        }
    }

    const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        let is_valid = validate.MobileNumber(val);

        setPhone(val);
        setPhoneValid(is_valid);

        
        if (
            isNameValid &&
            is_valid &&
            isEmailValid &&
            name.length > 0 &&
            val.length > 0 &&
            email.length > 0 &&
            file
        ) {
            setDone(true);
        }
    }

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        let is_valid = validate.Email(val);

        setEmail(val);
        setEmailValid(is_valid);

        if (
            isNameValid &&
            isPhoneValid &&
            is_valid &&
            name.length > 0 &&
            phone.length > 0 &&
            val.length > 0 &&
            file
        ) {
            setDone(true);
        }
    }

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.files?.[0];

        if (val) setFile(val);

        if (
            isNameValid &&
            isPhoneValid &&
            isEmailValid &&
            name.length > 0 &&
            phone.length > 0 &&
            email.length > 0 &&
            val
        ) {
            setDone(true);
        }
    }

    const submit = () => {
        if (!file) return;

        setLoading(true);

        let formData = new FormData();

        formData.append("full_name", name);
        formData.append("mobile", phone);
        formData.append("email", email);
        formData.append("cv", file, file.name);

        axios.post(`${API_BASE_URL}/jobs/${ props.id }/request`, formData, configAuthApi())
            .then(r => {
                if (r.status == 200 || r.status == 201) {
                    setSuccess(true);
                    setName("");
                    setPhone("");
                    setEmail("");
                    setErrors([]);
                    setDone(false);
                    setLoading(false);
                    setNameValid(true);
                    setPhoneValid(true);
                    setEmailValid(true);
                    setFile(null);
                }
            })
            .catch(function (error) {
                if (error.response.status == 422) {
                    let errors = error.response.data.errors;
                    let new_errors: string[] = [];
                    
                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid")
                        new_errors.push(errors[k][0]);
                    });

                    setErrors(new_errors);
                }
            })
            .finally(function () {
                setLoading(false);
            });
    }

    useEffect(() => {
        return () => {
            setName("");
            setPhone("");
            setEmail("");
            setErrors([]);
            setDone(false);
            setLoading(false);
            setNameValid(true);
            setPhoneValid(true);
            setEmailValid(true);
            setFile(null);
        }
    }, [props]);

    return (
        <>
            {
                isLoading && (
                    <div className="preloader">
                        <span className='loader'></span>
                    </div>
                )
            }
            {
                isSuccess && (
                    <div className="success" onClick={() => setSuccess(false)}>
                        <div className="icon">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <p>تم ارسال الطلب بنجاح</p>
                        <p className="close-text">انقر علي الشاشة لمتابعة التصفح</p>
                    </div>
                )
            }
            <div style={props.active ? {display:"block"}:{}} className={`modal fade ${props.active && 'show'}`} id="exampleModal" tabIndex={-1} 
            aria-labelledby="exampleModalLabel" onClick={props.changeActiveModal}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-body ps-3 ps-lg-3 py-4">
                            <h4 className="modal-heading-text mb-3">قدم الان!</h4>
                            <div  className="apply-form">
                                <div className="row">
                                    {
                                        Errors.length > 0 && (
                                            <div style={{ marginBottom: "0.5rem" }}>
                                                {
                                                    Errors.map((e, i) =>
                                                        (
                                                            <div className="alert alert-danger" style={{ marginBottom: "0.5rem" }} key={i}>
                                                                خطأ: { e }
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    <div className="col-12">
                                        <div className="job-apply-input-group">
                                            <label htmlFor="name">اسمك الكامل</label>
                                            <input type="text" name="full_name" id="name" className="job-apply-input"
                                                placeholder="اكتب اسمك هنا" value={name} onChange={onNameChange} />
                                            {
                                                !isNameValid && (
                                                    <p className="error-text mt-2">يجب أن تكتب علي الأقل ٥ احرف.</p>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="job-apply-input-group">
                                            <label htmlFor="phone">رقم التواصل</label>
                                            <input type="text" name="mobile" id="phone" className="job-apply-input"
                                                placeholder="أدخل رقم التواصل" value={phone} onChange={onPhoneChange} />
                                            {
                                                !isPhoneValid && (
                                                    <p className="error-text mt-2">يجب أن تكتب رقم التواصل بشكل دولي (يبدأ ب+966).</p>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="job-apply-input-group">
                                            <label htmlFor="email">الايميل</label>
                                            <input type="text" name="email" id="email" className="job-apply-input"
                                                placeholder="ادخل الايميل" value={email} onChange={onEmailChange} />
                                            {
                                                !isEmailValid && (
                                                    <p className="error-text mt-2">يجب أن تكتب البريد الالكتروني بشكل صحيح.</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="job-apply-input-group mb-0">
                                            <label htmlFor="">السيرة الذاتية</label>

                                        </div>
                                    </div>
                                    <div className="col-12 px-3">
                                        <label htmlFor='cv'  className="w-100" id="dropzone-area">
                                            <div className="dz-message needsclick">
                                                <img src={file ? "/assets/images/file-icon.svg" : "/assets/images/upload-icon.svg"} alt="" />
                                                <span className="note needsclick"><strong>
                                                    {
                                                        file
                                                            ? file.name
                                                            : "قم برفع سيرتك الذاتية"
                                                    }
                                                </strong></span>
                                            </div>
                                            <div className="fallback">
                                                <input id="cv"  className={"d-none"} name="cv" type="file" onChange={onFileChange} accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/*" />
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12">
                                        <button type="button" className={`job-form-apply-btn ${ !isDone && "disabled" }`} disabled={!isDone} onClick={() => submit()}>
                                            ارسال
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${props.active && 'modal-backdrop fade  show'}`} ></div>
            
        </>
    );
}

export default JobModal;
