import React from 'react';
import { FunctionComponent } from "react";

interface BenefitsProps {

}

const Benefits: FunctionComponent<BenefitsProps> = () => {

    return (
        <>
            <section id="benefits" className="section-element mb-4">
                <div className="container">
                    <div className="section-header">
                        <h6>
                            مزايا تمويل مبتكرة
                        </h6>
                        <p>
                            استفد من ميزاتنا الفريدة التي تجعل التمويل أسهل وأكثر أمانًا
                        </p>
                    </div>

                    <div className="row text-center text-md-start px-2 px-md-0">
                        <div className="col-12 col-sm-4 pe-md-5 pe-2">
                            <div className="benefit-item">
                                <img src="/assets/images/shield-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    تمويل سريع و آمن
                                </p>
                                <p className="sub-text">
                                    احصل على التمويل الذي تحتاجه بسرعة وبأمان تام عبر منصتنا، مع إجراءات مبسطة وسرعة في
                                    الموافقة.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 pe-md-5 pe-2">
                            <div className="benefit-item">
                                <img src="/assets/images/gauge-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    إدارة مالية متكاملة
                                </p>
                                <p className="sub-text">
                                    تابع جميع عملياتك المالية في مكان واحد، من سداد الأقساط إلى مراجعة السجلات المالية، لتبقى
                                    دائمًا على اطلاع بوضعك المالي. </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 pe-md-5 pe-2">
                            <div className="benefit-item">
                                <img src="/assets/images/partner-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    شراكات موثوقة
                                </p>
                                <p className="sub-text">
                                    استفد من شبكة واسعة من الشركاء والمتاجر المعتمدة التي تتيح لك استخدام التمويل بسهولة ومرونة
                                    عبر منصتنا.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Benefits;