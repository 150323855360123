import React, { useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";

interface SideAuthProps {

}
const SideAuth: FunctionComponent<SideAuthProps> = () => {
    return (
        <>
            <div className="col-12 col-lg-6 auth-columns auth-page-bg" style={{ minHeight: "100vh" }}>
                <div>
                    <img src="/assets/images/auth-vector.svg" alt="auth icons" className="img-fluid" />
                    <div className="auth-page-stepper-text">
                        <h4>هذا النص هو مثال لنص يمكن أن يستبدل</h4>
                        <p>
                            هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى،
                            حيث
                            يمكنك أن تولد مثل هذا النص أو العديد من النصوص.
                        </p>
                        <div className="steps-container">
                            <span className="step"></span>
                            <span className="step active"></span>
                            <span className="step"></span>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default SideAuth;

// import React,{ useEffect } from 'react';
// import { ACCESS_TOKEN_NAME, API_BASE_URL } from '../../constants/apiConstants';
// import axios from 'axios'
// function SideAuth(props) {
// useEffect(() => {
//     axios.get(API_BASE_URL+'/user/me', { headers: { 'token': localStorage.getItem(ACCESS_TOKEN_NAME) }})
//     .then(function (response) {
//         if(response.status !== 200){
//           redirectToLogin()
//         }
//     })
//     .catch(function (error) {
//       redirectToLogin()
//     });
//   },[])
//     return(
//         <div className="mt-2">
//             Home page content
//         </div>
//     )
// }

// export default SideAuth;