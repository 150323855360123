import React from 'react';
import { FunctionComponent } from "react";

interface FoundationsProps {

}

const Foundations: FunctionComponent<FoundationsProps> = () => {

    return (
        <>
            <section id="funds" className="section-element" style={{ marginTop: "3rem", marginBottom: "5rem" }}>
                <div className="container">
                    <div className="section-header">
                        <h6>
                            أسسنا الراسخة
                        </h6>
                        <p>
                            احصل على التمويل الذي تحتاجه بسهولة وسرعة من خلال طلب التمويل عبر منصتنا.
                        </p>
                    </div>

                    <div className="row text-center text-md-start">
                        <div className="col-12 col-lg-4 px-lg-4 mb-2 mb-lg-0 ">
                            <div className="benefit-item border-item" style={{ height: "100%" }}>
                                <img src="/assets/images/target-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    أهدافنا
                                </p>
                                <p className="sub-text">
                                    نهدف إلى تمكين الأفراد والشركات من تحقيق طموحاتهم المالية من خلال تقديم حلول مبتكرة وسهلة
                                    الاستخدام، تضمن لهم تجربة تمويل متميزة وموثوقة.
                                </p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 px-lg-4 mb-2 mb-lg-0 ">
                            <div className="benefit-item border-item" style={{ height: "100%" }}>
                                <img src="/assets/images/vision-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    رؤيتنا
                                </p>
                                <p className="sub-text">
                                    نتطلع إلى أن نصبح المنصة الرائدة في تقديم الخدمات المالية المبتكرة، مما يسهم في تحسين جودة
                                    الحياة وتسهيل التعاملات المالية على مستوى الأفراد والشركات.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 px-lg-4 mb-2 mb-lg-0 ">
                            <div className="benefit-item border-item" style={{ height: "100%" }}>
                                <img src="/assets/images/message-icon.svg" height="70px" width="70px" alt="icon" />
                                <p className="benefit-heading">
                                    رسالتنا
                                </p>
                                <p className="sub-text">
                                    نسعى لتقديم خدمات مالية متميزة، مبنية على الشفافية والموثوقية، لنساعد عملاءنا على تحقيق
                                    أهدافهم
                                    المالية بطريقة آمنة وفعالة.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Foundations;