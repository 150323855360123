import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import Partner, { PartnerProps } from '../../components/Partner/Partner';
import InfiniteScroll from 'react-infinite-scroll-component';

interface PartnersProps {

}

const Partners: FunctionComponent<PartnersProps> = () => {
    const [partners, setPartners] = useState<PartnerProps[]>([]);
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(2);

    const fetchData = ()=>{
        axios.get(`${API_BASE_URL}/partners?page=${page}`, configAuthApi())
            .then(response => {
                if (response.status == 200) {
                    let last_page = response.data.data.last_page;

                    if (page <= last_page) {
                        setPage(page + 1);
                        setLastPage(last_page);
                        setPartners(partners.concat(response.data.data.data));
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            })
    }
    
    useEffect(() => {
        axios.get(API_BASE_URL + '/partners', configAuthApi())
            .then(function (response) {
                if (response.status == 200) {    
                    setPage(page + 1);
                    setLastPage(response.data.data.last_page);
                    setPartners(response.data.data.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },[]);
    
    return (
        <>
            <section id="partners" className="section-element">
                <div className="container">
                    <div className="section-header">
                        <p style={{fontWeight: "bold"}}>
                            <a href="#" style={{color: "inherit"}} className="text-decoration-none">
                                الرئيسية
                            </a>
                        </p>
                        <h6 className="mb-4">شركائنا</h6>
                        {/* <div className="row"> */}
                            <InfiniteScroll
                                className='row'
                                dataLength={partners.length} //This is important field to render the next data
                                next={fetchData}
                                hasMore={page <= lastPage}
                                loader={
                                    <div className={"partners-grid"}>
                                        {
                                            new Array(10).fill(null).map((_, i) => (
                                                <div key={i} className="d-flex flex-column benefit-item border-item justify-content-center py-8" style={{ height: "172.6px" }}>
                                                    <div className="skeleton-box w-25"></div>
                                                    <div className="skeleton-box w-100 my-1"></div>
                                                    <div className="skeleton-box w-75 mb-1"></div>
                                                    <div className="skeleton-box w-50"></div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                                // below props only if you need pull down functionality
                                refreshFunction={fetchData}
                                pullDownToRefresh
                                pullDownToRefreshThreshold={50}
                                pullDownToRefreshContent={<></>}
                                releaseToRefreshContent={<></>}
                            >
                                <div className={"partners-grid"}>
                                    {partners?.map(v => (
                                        <Partner key={v.id} id={v.id} img={v.img} />
                                    ))}
                                </div>
                            </InfiniteScroll>
                        {/* </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Partners;