import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';

interface TermsProps {

}

const Terms: FunctionComponent<TermsProps> = () => {
    const [isLoaded, setLoaded] = useState(false);
    const [content, setContent] = useState("");

    useEffect(() => {
        axios.get(API_BASE_URL + '/terms-conditions?type=web', configAuthApi())
            .then(function (response) {
                if (response.status == 200) {             
                    setContent(response.data.data.page);
                    setLoaded(true);
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    },[]);

    return (
        <>
            <section id="terms" className="section-element">
                <div className="container">
                    <div className="section-header">
                        <p style={{ fontWeight: "bold" }}>
                            <a href="#" style={{ color: "inherit" }} className="text-decoration-none">
                                الرئيسية
                            </a>
                        </p>
                        <h6>الشروط و الاحكام</h6>
                        {
                            isLoaded ? (
                                <div
                                    className="privacy-items-container my-5"
                                    dangerouslySetInnerHTML={{ __html: content }}>    
                                </div>
                            ) : (
                                <div className="privacy-items-container my-5">
                                    <h3 className="mt-5">
                                        <span className="skeleton-box w-25"></span>
                                    </h3>
                                    <h5 className="d-flex flex-column">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-75 mb-2"></span>
                                        <span className="skeleton-box w-50"></span>
                                    </h5>
                                    <h3 className="mt-5">
                                        <span className="skeleton-box w-25"></span>
                                    </h3>
                                    <h5 className="d-flex flex-column">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-75 mb-2"></span>
                                        <span className="skeleton-box w-50"></span>
                                    </h5>
                                    <h3 className="mt-5">
                                        <span className="skeleton-box w-25"></span>
                                    </h3>
                                    <h5 className="d-flex flex-column">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-75 mb-2"></span>
                                        <span className="skeleton-box w-50"></span>
                                    </h5>
                                    <h3 className="mt-5">
                                        <span className="skeleton-box w-25"></span>
                                    </h3>
                                    <h5 className="d-flex flex-column">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-75 mb-2"></span>
                                        <span className="skeleton-box w-50"></span>
                                    </h5>
                                    <h3 className="mt-5">
                                        <span className="skeleton-box w-25"></span>
                                    </h3>
                                    <h5 className="d-flex flex-column">
                                        <span className="skeleton-box w-75"></span>
                                        <span className="skeleton-box w-75 my-2"></span>
                                        <span className="skeleton-box w-75 mb-2"></span>
                                        <span className="skeleton-box w-50"></span>
                                    </h5>
                                </div>
                            )
                        }
                    </div>

                </div>
            </section>
        </>
    );
}

export default Terms;