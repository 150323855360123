import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import { RegisterContext } from '../../pages/Register/Register';

const Step6: FunctionComponent = () => {
    const [isCopied, setCopied] = useState(false);
    const [lastTimeout, setLastTimeout] = useState(0);
    const [uid, setUid] = useState("");
    const { setLoading } = useContext(RegisterContext);

    const getUid = () => {
        return `${ uid.slice(0, 4) }-${ uid.slice(4, 8) }-${ uid.slice(8) }`;
    }

    const copyUid = () => {
        if (lastTimeout != 0) {
            clearInterval(lastTimeout);
        }

        navigator.clipboard.writeText(getUid());
        setCopied(true);

        let last_timeout: any = setTimeout(() => {
            setCopied(false);
        }, 4000);

        setLastTimeout(last_timeout);
    }

    useEffect(() => {
        setLoading?.(true);

        axios.get(`${API_BASE_URL}/auth/my-data`, configAuthApi())
            .then(function (response) {
                if (response.status == 200) {
                    let data = response.data.data;

                    axios.get(`${API_BASE_URL}/auth/me`, configAuthApi())
                        .then(function (response) {
                            if (
                                response.status == 200 &&
                                data.type == "provider"
                            ) {
                                setUid(response.data.data.uid);
                            }
                        })
                        .finally(() => setLoading?.(false))
                }
            })
            .catch(() => setLoading?.(false));
    }, []);

    return (
        <div className="row">
                <div className="col-12">
                    <div className="auth-form-elements-group">
                        {
                            uid == "" ? (
                                <div className="success-box" style={{ marginTop: "4rem", marginBottom: "3rem" }}>
                                    <div className="icon">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    <h5>تم أنشاء الحساب بنجاح</h5>
                                </div>
                            ) : (
                                <div style={{ marginTop: "4rem", marginBottom: "4rem" }}>
                                    <label htmlFor="uid" className="auth-form-label">رقم المورد</label>
                                    <div className="position-relative">
                                        <input type="text" name="uid" id="text" className="auth-form-text-input" disabled defaultValue={getUid()} />
                                        <button className="copy-btn" onClick={copyUid}>
                                            <svg width="17px" height="17px" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                    strokeLinejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <g clip-path="url(#a)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M8 5h7.795c1.115 0 1.519.116 1.926.334.407.218.727.538.945.945.218.407.334.811.334 1.926V16a1 1 0 1 0 2 0V8.128c0-1.783-.186-2.43-.534-3.082a3.635 3.635 0 0 0-1.512-1.512C18.302 3.186 17.655 3 15.872 3H8a1 1 0 0 0 0 2zm7.721 2.334C15.314 7.116 14.91 7 13.795 7h-7.59c-1.115 0-1.519.116-1.926.334a2.272 2.272 0 0 0-.945.945C3.116 8.686 3 9.09 3 10.205v7.59c0 1.114.116 1.519.334 1.926.218.407.538.727.945.945.407.218.811.334 1.926.334h7.59c1.114 0 1.519-.116 1.926-.334.407-.218.727-.538.945-.945.218-.407.334-.811.334-1.926v-7.59c0-1.115-.116-1.519-.334-1.926a2.272 2.272 0 0 0-.945-.945z"
                                                            fill="#fff"></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="a">
                                                            <path fill="#fff" d="M0 0h24v24H0z"></path>
                                                        </clipPath>
                                                    </defs>
                                                </g>
                                            </svg>
                                            {
                                                isCopied
                                                    ? " تم النسخ "
                                                    : " نسخ "
                                            }
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
    );
}

export default Step6;