import React from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";

interface NoPageProps {

}

const NoPage: FunctionComponent<NoPageProps> = () => {
    return (
        <h1>NoPage</h1>
    );
}

export default NoPage;