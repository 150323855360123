import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet, useOutletContext } from "react-router-dom";
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import JobCard from '../../components/JobCard/JobCard';
import JobModal from '../../components/JobModal/JobModal';
import InfiniteScroll from 'react-infinite-scroll-component';

interface JobsProps {

}
interface NameIdInterface {
    id?: number
    name?: string
}
interface JobInterface {
    id?: number
    title?: string
    content?: string
    created_at?: string
    tags?: NameIdInterface[]
    changeJobId?: any
}

const Jobs: FunctionComponent<JobsProps> = () => {
    const SearchBtn = useRef<HTMLButtonElement|null>(null);
    const [types, setTypes] = useState<NameIdInterface[]>();
    const [cities, setCities] = useState<NameIdInterface[]>();
    const [categories, setCategories] = useState<NameIdInterface[]>();
    const [jobs, setJobs] = useState<JobInterface[]>([]);
    const [jobId, setJobId] = useState(0);
    const [activeModal, setActiveModal] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(2);
    const [keyWords, setKeyWords] = useState("");
    const [cityId, setCityId] = useState(0);
    const [typeId, setTypeId] = useState(0);
    const [categoryId, setCategoriyId] = useState(0);
    const [urlParams, setUrlParams] = useState("");
    const [isAuth]: boolean[] = useOutletContext();
    
    const changeJobId = (id: number) => {
        if (localStorage.getItem("jwt-token")) {
            setJobId(id);
            setActiveModal(true);
        } else {
            localStorage.setItem("job-id-redirect", id.toString());

            setTimeout(() => {
                window.location.href = "/register?selector=customer";
            }, 100);
        }
    }

    const changeActiveModal = (event: React.MouseEvent) => {
        let dataValue = (event.target as HTMLElement).classList.contains('modal');
        dataValue = dataValue || (event.target as HTMLElement).classList.contains('modal-dialog');
        if (dataValue) {
            setActiveModal(false);
        };
    }

    const fetchData = ()=>{
        axios.get(`${API_BASE_URL}/jobs?page=${page}${urlParams}`, configAuthApi())
            .then(response => {
                if (response.status == 200) {
                    let last_page = response.data.meta.last_page;

                    if(page <= last_page){
                        let data = response.data.data
                            .map((info: JobInterface) => {
                                if (info.created_at) {
                                    let date = new Date(info.created_at);
                                    let year = date.getFullYear();
                                    let month = date.toLocaleString("ar", { month: "long" });;
                                    let day = date.getDate();
                                    let day_name = date.toLocaleString("ar", { weekday: "long" });
                                    info.created_at = `${ day_name } ${ day } ${ month } ${ year }`;
                                }

                                return info
                            });

                        setPage(page + 1);
                        setLastPage(last_page);
                        setJobs(jobs.concat(data));
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    const searchData = ()=>{
        setPage(1);
        setJobs([]);
        setLastPage(2);

        let search_data = [];

        if (keyWords != "") search_data.push("search=" + keyWords);
        if (typeId != 0) search_data.push("type_id=" + typeId);
        if (categoryId != 0) search_data.push("category_id=" + categoryId);
        if (cityId != 0) search_data.push("city_id=" + cityId);

        let params = `&${ search_data.join("&") }`;

        setUrlParams(params);

        axios.get(`${API_BASE_URL}/jobs?page=1${params}`, configAuthApi())
            .then(response => {
                if (response.status == 200) {
                    let data = response.data.data
                        .map((info: JobInterface) => {
                            if (info.created_at) {
                                let date = new Date(info.created_at);
                                let year = date.getFullYear();
                                let month = date.toLocaleString("ar", { month: "long" });;
                                let day = date.getDate();
                                let day_name = date.toLocaleString("ar", { weekday: "long" });
                                info.created_at = `${ day_name } ${ day } ${ month } ${ year }`;
                            }

                            return info
                        });

                    setPage(2);
                    setLastPage(response.data.meta.last_page);
                    setJobs(data);
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    useEffect(() => {
        setTimeout(() => {
            axios.get(`${API_BASE_URL}/jobs/cities`, configAuthApi())
                .then(response => {
                    if (response.status == 200) {
                        setCities(response.data.data.data)
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }, 150);

        setTimeout(() => {
            axios.get(`${API_BASE_URL}/jobs/categories`, configAuthApi())
                .then(response => {
                    if (response.status == 200) {
                        setCategories(response.data.data.data)
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }, 300);

        setTimeout(() => {
            axios.get(`${API_BASE_URL}/jobs/types`, configAuthApi())
                .then(response => {
                    if (response.status == 200) {
                        setTypes(response.data.data.data)
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }, 450);

        setTimeout(() => {
            axios.get(`${API_BASE_URL}/jobs`, configAuthApi())
                .then(response => {
                    if (response.status == 200) {
                        let data = response.data.data
                            .map((info: JobInterface) => {
                                if (info.created_at) {
                                    let date = new Date(info.created_at);
                                    let year = date.getFullYear();
                                    let month = date.toLocaleString("ar", { month: "long" });;
                                    let day = date.getDate();
                                    let day_name = date.toLocaleString("ar", { weekday: "long" });
                                    info.created_at = `${ day_name } ${ day } ${ month } ${ year }`;
                                }

                                return info
                            });

                        setPage(page + 1);
                        setLastPage(response.data.meta.last_page);
                        setJobs(data)

                        let init_job_id = localStorage.getItem("job-id-redirect");

                        if (isAuth && init_job_id) {
                            let num_id = Number(init_job_id);
                            localStorage.removeItem("job-id-redirect");
                            changeJobId(num_id);
                        }
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }, 600);
    }, []);

    return (
        <>
            <section id="jobs" className="section-element">
                <div className="container">
                    <div className="section-header">
                        <p style={{ fontWeight: "bold" }}>
                            <a style={{ color: "inherit" }} className="text-decoration-none">
                                الرئيسية
                            </a>
                        </p>
                        <h6>الوظائف</h6>
                    </div>

                    <div className="jobs-filter-container">
                        <div className="row">

                            <div className="col-12 col-lg-11 jobs-filter-elements-group magnifier-glass mb-4">
                                <input type="text" name="name" className="jobs-filter-input" placeholder="أدخل كلمة البحث هنا" onChange={(e) => { let val = e.target.value; setKeyWords(val); setTimeout(() => { if (val.trim().length == 0) SearchBtn.current?.click(); }, 500); }} />
                            </div>
                            <div
                                className="col-12 col-lg-1 jobs-filter-elements-group d-flex justify-content-center align-items-center mb-4">
                                <button type="button" className="jobs-filter-submit-btn" onClick={searchData} ref={SearchBtn}>بحث</button>
                            </div>

                            <div className="col-12 col-lg-4 jobs-filter-group dropdown-arrow mb-4 mb-lg-0">
                                <select className="jobs-filter-input select"  onChange={(e) => setCityId(parseInt(e.target.value))}>
                                    <option value="0" style={{color: "#000000"}}>اختر المدينة</option>
                                    {cities?.map(v => (<option key={v.id} value={v.id} style={{color: "#000000"}}>{v.name}</option>))}
                                </select>
                            </div>
                            <div className="col-12 col-lg-4 jobs-filter-group dropdown-arrow mb-4 mb-lg-0">
                                <select className="jobs-filter-input select"  onChange={(e) => setTypeId(parseInt(e.target.value))}>
                                    <option value="0" style={{color: "#000000"}}>اختر نوع الوظيفة</option>
                                    {types?.map(v => (<option key={v.id} value={v.id} style={{color: "#000000"}}>{v.name}</option>))}

                                </select>
                            </div>
                            <div className="col-12 col-lg-4 jobs-filter-group dropdown-arrow mb-4 mb-lg-0">
                                <select className="jobs-filter-input select" onChange={(e) => setCategoriyId(parseInt(e.target.value))}>
                                    <option value="0" style={{color: "#000000"}}>اختر الفئة</option>
                                    {categories?.map(v => (<option key={v.id} value={v.id} style={{color: "#000000"}}>{v.name}</option>))}
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className="jobs-list mt-5">
                        {/* <div className="row"> */}
                            <InfiniteScroll
                                className='row'
                                dataLength={jobs.length} //This is important field to render the next data
                                next={fetchData}
                                hasMore={page <=  lastPage}
                                loader={
                                    <>
                                        {
                                            new Array(10).fill(null).map((_, i) => (
                                                <div className="col-12 col-lg-6 mb-3" key={i}>
                                                    <div className="single-job-item">
                                                        <div className="single-job-top d-flex justify-content-between align-items-center">
                                                            <div className="single-job-top-text">
                                                                <p className="datetime m-0">
                                                                    <span className="skeleton-box" style={{ width: "12rem" }}></span>
                                                                </p>
                                                                <h5 className="job-title m-0">
                                                                    <span className="skeleton-box" style={{ width: "8rem" }}></span>
                                                                </h5>
                                                            </div>
                                                            <div className="single-job-cta">
                                                                <button className="cta cta-thic px-8 py-4" disabled>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <p className="job-description">
                                                            <span className="skeleton-box w-75"></span>
                                                            <span className="skeleton-box w-75 my-2"></span>
                                                            <span className="skeleton-box w-50"></span>
                                                        </p>
                                                        <div className="tags-container pt-3">
                                                            <span className="tag cta cta-light px-6 py-4 me-2 d-inline-block"></span>
                                                            <span className="tag cta cta-light px-6 py-4 d-inline-block"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                }
                                // below props only if you need pull down functionality
                                refreshFunction={fetchData}
                                pullDownToRefresh
                                pullDownToRefreshThreshold={50}
                                pullDownToRefreshContent={<></>}
                                releaseToRefreshContent={<></>}
                            >
                                {jobs?.map(v => (<JobCard key={v.id} id={v.id} title={v.title}
                                    content={v.content} tags={v.tags} created_at={v.created_at} changeJobId={changeJobId} />))}
                            </InfiniteScroll>
                            {
                                !(page <=  lastPage) &&
                                jobs.length == 0 && (
                                    <h5 style={{ marginTop: "2rem", textAlign: "center" }}>
                                        لا يوجد وظائف بهذه المواصفات في الوقت الحالي
                                    </h5>
                                )
                            }
                        {/* </div> */}
                    </div>
                </div>
            </section>

            <JobModal id={jobId} active={activeModal} changeActiveModal={changeActiveModal} />
        </>
    );
}

export default Jobs;
export type { JobInterface }