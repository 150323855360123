import React, { useState } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import SideAuth from '../../components/SideAuth/SideAuth';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/apiConstants';
import Step1 from '../../containers/ForgotPasswordSteps/Step1';
import Step2 from '../../containers/ForgotPasswordSteps/Step2';
import Step3 from '../../containers/ForgotPasswordSteps/Step3';

interface ForgotPasswordProps {

}

interface stateData {
    email?: string;
    forget_code?: string
}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = () => {
    const [isLoading, setLoading] = useState(false);
    const [Errors, setErrors] = useState<string[]>([]);
    const [step, setStep] = useState(1);
    const [dataDone, setDataDone] = useState(false);
    const [formData, setFormData] = useState({});

    const modifyState = (done: boolean, data: stateData) => {
        setDataDone(done)
        switch (step) {
            case 1:
                setFormData(data)
                break;
            case 2:
                setFormData({ ...formData, ...data})
                break;
            case 3:
                setFormData({ ...formData, ...data})
                break;
            default:
                break;
        }
    }

    const nextPage = () => {
        if (dataDone) {
            switch (step) {
                case 1:
                    sendEmailApi();
                    break;
                case 2:
                    checkCodeApi();
                    break;
                case 3:
                    resetPasswordApi();
                    break;
                default:
                    break;
            }
        }
    }

    const sendEmailApi = () => {
        setLoading(true);

        axios.post(`${API_BASE_URL}/auth/send-email`, formData)
            .then(function (response) {
                if (response.status == 200) {
                    setErrors([]);
                    setStep(2);
                    setDataDone(false);
                }
            })
            .catch(function (error) {
                let new_errors: string[] = [];

                if (error.response.status == 422) {
                    let errors = error.response.data.errors;
                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                        new_errors.push(errors[k][0]);
                    });
                }

                if (error.response.status == 403) {
                    new_errors.push(error.response.data.message);
                }

                setErrors(new_errors);
            })
            .finally(function () {
                setLoading(false);
            });
    }

    const checkCodeApi = () => {
        setLoading(true);

        axios.post(`${API_BASE_URL}/auth/check-code`, formData)
            .then(function (response) {
                if (response.status == 200) {
                    if (response.data.success) {
                        setErrors([]);
                        setStep(3);
                        setDataDone(false);
                    } else {
                        setErrors([response.data.message]);
                    }
                }
            })
            .catch(function (error) {
                let new_errors: string[] = [];

                if (error.response.status == 422) {
                    let errors = error.response.data.errors;
                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                        new_errors.push(errors[k][0]);
                    });
                }

                setErrors(new_errors);
            })
            .finally(function () {
                setLoading(false);
            });
    }

    const resetPasswordApi = () => {
        setLoading(true);

        axios.post(`${API_BASE_URL}/auth/forget-password`, formData)
            .then(function (response) {
                if (response.status == 200 && response.data.success) {
                    localStorage.setItem('jwt-token', response.data.data.token);
                    setErrors([]);
                    setStep(4);
                }
            })
            .catch(function (error) {
                let new_errors: string[] = [];

                if (error.response.status == 422) {
                    let errors = error.response.data.errors;
                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                        new_errors.push(errors[k][0]);
                    });
                }

                if (error.response.status == 400) {
                    new_errors.push(error.response.data.message);
                }

                setErrors(new_errors);
            })
            .finally(function () {
                setLoading(false);
            });
    }

    return (
        <>
            {
                isLoading && (
                    <div className="preloader">
                        <div className="loader"></div>
                    </div>
                )
            }
            <div className="full-height">
                <div className="row auth-container">
                    <div className="col-12 col-lg-6 auth-columns">
                        <div className="full-height auth-form-column">
                            <div>
                                <a href="/">
                                    <img src="/assets/images/logo.svg" alt="logo" className="mb-5" />
                                </a>
                                <div className="auth-form-text mt-md-3" style={{ marginBottom: "4rem" }}>
                                    <h1>
                                        { step == 1 && "تغيير كلمة المرور" }
                                        { step == 2 && "كود اعادة التعيين" }
                                        { step == 3 && "تعيين كلمة المرور" }
                                        { step == 4 && "تهانينا" }
                                    </h1>
                                    <p>
                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى.
                                    </p>
                                </div>
                            </div>
                            <div className="auth-form-inputs">
                                {
                                    Errors.length > 0 && (
                                        <div style={{ marginBottom: "2rem" }}>
                                            {
                                                Errors.map((e, i) =>
                                                    (
                                                        <div className="alert alert-danger" style={{ marginBottom: "0.5rem" }} key={i}>
                                                            خطأ: { e }
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                }

                                { step == 1 && <Step1 key={1} modifyState={modifyState}  /> }
                                { step == 2 && <Step2 key={2} modifyState={modifyState}  /> }
                                { step == 3 && <Step3 key={3} modifyState={modifyState}  /> }
                                {
                                    step == 4 && (
                                        <div className="success-box">
                                            <div className="icon">
                                                <div className="check-icon">
                                                    <span className="icon-line line-tip"></span>
                                                    <span className="icon-line line-long"></span>
                                                    <div className="icon-circle"></div>
                                                    <div className="icon-fix"></div>
                                                </div>
                                            </div>
                                            <h5>تم اعادة تعيين كلمة المرور بنجاح</h5>
                                        </div>
                                    )
                                }
                            </div>

                            <div style={{ paddingBottom: "4rem" }}>
                                <div className="auth-form-submit-button-container mb-3">
                                    {
                                        step == 4 ? (
                                            <Link to={localStorage.getItem("job-id-redirect") ? "/jobs" : "/"} type="button" className={`auth-form-homepage-button btn`}>
                                                {
                                                    localStorage.getItem("job-id-redirect")
                                                        ? "أكمال التقديم علي الوظيفة"
                                                        : "الانتقال للصفحة الرئيسية"
                                                }
                                            </Link>
                                        ) : (
                                            <button className={`auth-form-submit-button ${ !dataDone && "disabled" }`} onClick={() => dataDone && nextPage()} disabled={!dataDone}>
                                                { step == 1 && "طلب تغيير كلمة المرور" }
                                                { step == 2 && "التالي" }
                                                { step == 3 && "تأكيد" }
                                            </button>
                                        )
                                    }
                                </div>
                                {
                                    step == 1 && (
                                        <div className="create-account-container text-center">
                                            <span>هل تذكرت كلمة المرور ؟ </span>
                                            <Link to="/login" className="auth-form-anchors font-bold">تسجيل الدخول</Link>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <SideAuth />
                </div >
            </div>
        </>
    );
}

export default ForgotPassword;