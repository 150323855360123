import React, { ChangeEvent, useState } from 'react';
import { FunctionComponent } from "react";
import validate from '../../utils/validation';

interface Step1Props {
    modifyState: any
}

const Step1: FunctionComponent<Step1Props> = (props) => {
    const [isEmailValid, setEmailValid] = useState(true);
    const [email, setEmail] = useState('');

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        let new_email = e.target.value;
        let is_valid = validate.Email(new_email);

        setEmail(new_email);
        setEmailValid(is_valid);

        if (new_email.length > 0 && is_valid) {
            props.modifyState(true, { email: new_email });
        }
    }

    return (
        <>
            <div className="auth-form-elements-group">
                <label htmlFor="email" className="auth-form-label">البريد الالكتروني</label>
                <input type="email" name="email" id="email" className={`auth-form-text-input mb-2 ${!isEmailValid && 'was-validated'}`}
                    placeholder="اكتب بريدك الالكتروني هنا"
                    value={email}
                    onChange={onEmailChange}
                />
                {
                    !isEmailValid && (
                        <p className="error-text">يجب كتابة البريد الالكتروني بشكل صحيح.</p>
                    )
                }
            </div>
        </>
    );
}

export default Step1;
