import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import SideAuth from '../../components/SideAuth/SideAuth';
import Question, { QuestionProps } from '../../components/Question/Question';
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import InfiniteScroll from 'react-infinite-scroll-component';

interface FaqProps {

}
const Faq: FunctionComponent<FaqProps> = () => {
    const [questions,setQuestions] = useState<QuestionProps[]>([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(2);
    
    const fetchData = ()=>{
        axios.get(`${API_BASE_URL}/questions?type=web&page=${page}`, configAuthApi())
            .then(response => {
                if (response.status == 200) {
                    let last_page = response.data.data.last_page;

                    if (page <= last_page){
                        setPage(page + 1);
                        setLastPage(last_page);
                        setQuestions(questions.concat(response.data.data.data));
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    useEffect(() => {
        axios.get(API_BASE_URL + '/questions?type=web', configAuthApi())
            .then(function (response) {
                if (response.status == 200) {             
                    setPage(page + 1);
                    setLastPage(response.data.data.last_page);
                    setQuestions(response.data.data.data);
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    },[]);

    return (
        <>
            <section id="faqs" className="section-element">
                <div className="container">
                    <div className="section-header">
                        <p style={{fontWeight: "bold"}}>
                            <a href="#" style={{color: "inherit"}} className="text-decoration-none">
                                الرئيسية
                            </a>
                        </p>
                        <h6>الأسئلة الشائعة</h6>
                    </div>
                    <div className="accordion-container mb-5">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="questions-list">
                                {/* <div className="row"> */}
                                    <InfiniteScroll
                                        className='row'
                                        dataLength={questions.length} //This is important field to render the next data
                                        next={fetchData}
                                        hasMore={page <= lastPage}
                                        loader={
                                            <>
                                                {
                                                    new Array(9).fill(null).map((_, i) => (
                                                        <Question key={i} />
                                                    ))
                                                }
                                            </>
                                        }
                                        // below props only if you need pull down functionality
                                        refreshFunction={fetchData}
                                        pullDownToRefresh
                                        pullDownToRefreshThreshold={50}
                                        pullDownToRefreshContent={<></>}
                                        releaseToRefreshContent={<></>}
                                    >
                                        {questions?.map(v => (
                                            <Question key={v.id} id={v.id} question={v.question} answer={v.answer} />
                                        ))}
                                    </InfiniteScroll>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;