import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, useSearchParams } from "react-router-dom";
import SideAuth from '../../components/SideAuth/SideAuth';
import Step1 from '../../containers/RegisterSteps/Step1';
import Step2 from '../../containers/RegisterSteps/Step2';
import Step3 from '../../containers/RegisterSteps/Step3';
import Step4 from '../../containers/RegisterSteps/Step4';
import Step5 from '../../containers/RegisterSteps/Step5';
import Step6 from '../../containers/RegisterSteps/Step6';
import validate from '../../utils/validation';
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';

interface OTPData {
    "otp_code"?: string;
}

interface RegisterData {
    "email"?: string;
    "phone"?: string;
    "mobile"?: string;
    "password"?: string;
    "confirm"?: string;
    "type"?: "provider"|"customer";
    "platform"?: "web";
    "fcm_token"?: string;
    "agree"?: boolean;
}

interface CompanyData {
    "commercial_register"?: string,
    "name"?: string,
    "company_specialization_id"?: number,
    "capital"?: string,
    "tax_number"?: string,
    "email"?: string,
    "address"?: string|null,
    "count_employees"?: number|null,
    "company_phone"?: string,
    "company_mobile"?: string,
    "representative_name"?: string,
    "representative_phone"?: string,
    "representative_mobile"?: string,
    "representative_email"?: string,
    "credit_inquiry"?: string,
    "agree"?: boolean,
}

interface RegisterContextProps {
    isLoading?: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    user?: RegisterData;
    setUser?: React.Dispatch<React.SetStateAction<RegisterData>>;
    company?: CompanyData;
    setCompany?: React.Dispatch<React.SetStateAction<CompanyData>>;
    otp?: OTPData;
    setOtp?: React.Dispatch<React.SetStateAction<OTPData>>;
    validators?: {
        user: {
            phone: () => boolean;
            email: () => boolean;
            password: () => boolean;
            confirm: () => boolean;
        };
        company: {
            commercial_register: () => boolean;
            name: () => boolean;
            company_specialization_id: () => boolean;
            capital: () => boolean;
            tax_number: () => boolean;
            email: () => boolean;
            address: () => boolean;
            count_employees: () => boolean;
            company_phone: () => boolean;
            representative_name: () => boolean;
            representative_phone: () => boolean;
            representative_email: () => boolean;
        };
    };
}

const RegisterContext = React.createContext<RegisterContextProps>({});

const Register: FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState<string[]>([]);
    const [user, setUser] = useState<RegisterData>({ platform: "web" });
    const [otp, setOtp] = useState<OTPData>({});
    const [company, setCompany] = useState<CompanyData>({});

    useEffect(() => {
        if (searchParams.has("selector")) {
            let type = searchParams.get("selector")?.trim().toLowerCase();

            if (type == "customer" || type == "provider") {
                setUser({ ...user, type });
            }
        }

        setSearchParams({});
    }, []);

    const validators = {
        user: {
            phone: () => Boolean(user.phone && user.phone.length > 0 && validate.MobileNumber(user.mobile ?? "")),
            email: () => Boolean(user.email && user.email.length > 0 && validate.Email(user.email)),
            password: () => Boolean(user.password && user.password.length >= 6 && validate.Password(user.password)),
            confirm: () => Boolean(user.confirm && user.password && user.confirm === user.password)
        },
        company: {
            commercial_register: () => Boolean(
                company.commercial_register &&
                company.commercial_register.length >= 6 &&
                company.commercial_register.length <= 50
            ),
            name: () => Boolean(company.name && company.name.length >= 3 && company.name.length <= 50),
            company_specialization_id: () => Boolean(
                company.company_specialization_id &&
                typeof company.company_specialization_id == "number" &&
                !isNaN(company.company_specialization_id) &&
                company.company_specialization_id > 0
            ),
            capital: () => Boolean(
                company.capital &&
                Number(company.capital) >= 100
            ),
            tax_number: () => Boolean(
                company.tax_number &&
                company.tax_number.length >= 6 &&
                company.tax_number.length <= 50
            ),
            email: () => Boolean(company.email && company.email.length > 0 && validate.Email(company.email)),
            address: () => Boolean(
                !company.address || company.address?.length == 0 || (
                    company.address.length >= 5 &&
                    company.address.length <= 50
                )
            ),
            count_employees: () => Boolean(
                (
                    !company.count_employees &&
                    company.count_employees !== 0
                ) || (
                    company.count_employees > 0
                )
            ),
            company_phone: () => Boolean(
                company.company_phone &&
                company.company_phone.length > 0 &&
                validate.MobileNumber(company.company_mobile ?? "")
            ),
            representative_name: () => Boolean(
                company.representative_name &&
                company.representative_name.length >= 3 &&
                company.representative_name.length <= 50
            ),
            representative_phone: () => Boolean(
                company.representative_phone &&
                company.representative_phone.length > 0 &&
                validate.MobileNumber(company.representative_mobile ?? "")
            ),
            representative_email: () => Boolean(
                company.representative_email &&
                company.representative_email.length > 0 &&
                validate.Email(company.representative_email)
            )
        }
    };

    const isDataDone = () => {
        let done = false;

        switch (step) {
            case 1:
                done = Boolean(user.type);
                break;
            case 2:
                done = Boolean(
                    validators.user.phone() &&
                    validators.user.email() &&
                    validators.user.password() &&
                    validators.user.confirm() &&
                    user.agree === true
                );
                break;
            case 3:
                done = validators.company.commercial_register();
                break;
            case 4:
                done = Boolean(
                    validators.company.name() &&
                    validators.company.company_specialization_id() &&
                    validators.company.capital() &&
                    validators.company.tax_number() &&
                    validators.company.email() &&
                    validators.company.address() &&
                    validators.company.count_employees() &&
                    validators.company.company_phone() &&
                    validators.company.representative_name() &&
                    validators.company.representative_phone() &&
                    validators.company.representative_email() &&
                    company.agree === true
                );
                break;
            case 5:
                done = Boolean(otp.otp_code && otp.otp_code.length == 6);
                break;
        };

        return done;
    }

    const previousPage = () => {
        setErrors([]);
        setStep(step - 1);
    }

    const nextPage = () => {
        let current_step = step;

        switch (current_step) {
            case 2:
                validateRegisterApi();
                break;
            case 3:
                queryCommercialRegisterApi();
                break;
            case 4:
                validateCompanyApi();
                break;
            case 5:
                verifyMobileApi();
                break;
            default:
                document.querySelectorAll(`input`).forEach(item => {
                    item.classList.remove("is-invalid");
                });
                setErrors([]);
                setStep(step + 1);
        }
    }

    const validateRegisterApi = () => {
        setLoading(true);

        axios.post(`${ API_BASE_URL }/auth/validate-register`, user, configAuthApi())
            .then(response => {
                if (response.status === 200) {
                    document.querySelectorAll(`input`).forEach(item => {
                        item.classList.remove("is-invalid");
                    });
                    
                    setErrors([]);
                    setStep(step + 1);
                }
            })
            .catch(error => {
                if (error.response.status == 422) {
                    let errors = error.response.data.errors;
                    let new_errors: string[] = [];

                    document.querySelectorAll(`input`).forEach(item => {
                        item.classList.remove("is-invalid");
                    });

                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                        new_errors.push(errors[k][0]);
                    });

                    setErrors(new_errors);
                }
            })
            .finally(() => setLoading(false));
    }

    const queryCommercialRegisterApi = () => {
        setLoading(true);

        axios.get(`${ API_BASE_URL }/companies/query?commercial_register=${ company.commercial_register }`, configAuthApi())
            .then(response => {
                if (response.status === 200) {
                    document.querySelectorAll(`input`).forEach(item => {
                        item.classList.remove("is-invalid");
                    });

                    setErrors([]);
                    setStep(step + 1);
                }
            })
            .catch(error => {
                document.querySelectorAll(`input`).forEach(item => {
                    item.classList.remove("is-invalid");
                });
                
                if (error.response.status === 400) {
                    document.querySelectorAll(`input`).forEach(item => {
                        item.classList.remove("is-invalid");
                    });

                    setErrors([error.response.data.message]);
                }

                if (error.response.status === 422) {
                    let errors = error.response.data.errors;
                    let new_errors: string[] = [];

                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                        new_errors.push(errors[k][0]);
                    });

                    setErrors(new_errors);
                }

                setLoading(false);
            });
    }

    const validateCompanyApi = () => {
        setLoading(true);

        setUser({ ...user, phone: user.mobile });

        let companyData = company;

        if (companyData.address?.length == 0) {
            companyData.address = null;
        }

        if (!companyData.count_employees && company.count_employees !== 0) {
            companyData.count_employees = null;
        }

        axios.post(`${ API_BASE_URL }/companies/validate`, companyData, configAuthApi())
            .then(response => {
                if (response.status === 200) {
                    document.querySelectorAll(`input`).forEach(item => {
                        item.classList.remove("is-invalid");
                    });

                    setErrors([]);
                    setStep(step + 1);
                    submitApi();
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    let errors = error.response.data.errors;
                    let new_errors: string[] = [];

                    document.querySelectorAll(`input`).forEach(item => {
                        item.classList.remove("is-invalid");
                    });
                    
                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                        new_errors.push(errors[k][0]);
                    });

                    setLoading(false);
                    setErrors(new_errors);
                }
            });
    }

    const submitApi = () => {
            axios.post(`${ API_BASE_URL }/auth/register`, user, configAuthApi())
                .then(async response => {
                    if (response.status === 200) {
                        localStorage.setItem('jwt-token', response.data.data.token);

                        let companyData = company;

                        if (companyData.address?.length == 0) {
                            companyData.address = null;
                        }
                
                        if (!companyData.count_employees && company.count_employees !== 0) {
                            companyData.count_employees = null;
                        }

                        axios.post(`${API_BASE_URL}/companies`,
                            companyData,
                            configAuthApi()
                        )
                        .then(() => {})
                        .catch(error => {
                            setStep(4);
            
                            if (error.response.status === 422) {
                                let errors = error.response.data.errors;
                                let new_errors: string[] = [];
            
                                document.querySelectorAll(`input`).forEach(item => {
                                    item.classList.remove("is-invalid");
                                });

                                Object.keys(errors).forEach(k => {
                                    document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                                    new_errors.push(errors[k][0]);
            
                                    if (k == "commercial_register") {
                                        setStep(3);
                                    }
                                });
            
                                setErrors(new_errors);
                            }
                        })
                        .finally(() => setLoading(false));
                    }
                })
                .catch(error => {
                    setStep(2);
                    setLoading(false);

                    if (error.response.status === 422) {
                        let errors = error.response.data.errors;
                        let new_errors: string[] = [];

                        document.querySelectorAll(`input`).forEach(item => {
                            item.classList.remove("is-invalid");
                        });

                        Object.keys(errors).forEach(k => {
                            document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid");
                            new_errors.push(errors[k][0]);
                        });

                        setErrors(new_errors);
                    }
                });
    }

    const verifyMobileApi = () => {
        setLoading(true);

        axios.post(`${ API_BASE_URL }/companies/mobile-verified`, otp, configAuthApi())
            .then(function (response) {
                if (response.status == 200) {
                    document.querySelectorAll(`input`).forEach(item => {
                        item.classList.remove("is-invalid");
                    });
                    
                    setErrors([]);
                    setStep(step + 1);
                }
            })
            .catch(function (error) {
                document.querySelectorAll(`input`).forEach(item => {
                    item.classList.remove("is-invalid");
                });

                if (error.response.status == 422) {
                    let errors = error.response.data.errors;
                    let new_errors: string[] = [];

                    Object.keys(errors).forEach(k => {
                        document.querySelector(`input[name=${k}]`)?.classList.add("is-invalid")
                        new_errors.push(errors[k][0]);
                    });

                    setErrors(new_errors);
                }

                if (error.response.status == 403) {
                    setErrors([error.response.data.message]);
                }
            })
            .finally(function () {
                setLoading(false);
            });
    }

    return (
        <RegisterContext.Provider
                value={{
                    isLoading, setLoading,
                    user, setUser,
                    company, setCompany,
                    otp, setOtp,
                    validators
                }}
            >
            {
                isLoading && (
                    <div className="preloader">
                        <span className='loader'></span>
                    </div>
                )
            }
            <div className="full-height">
                <div className="row auth-container">
                    <div className="col-12 col-lg-6 auth-columns">
                        <div id="register-container" className="full-height auth-form-column">
                            <div>
                                <Link to="/">
                                    <img src="/assets/images/logo.svg" alt="logo" className="mb-5" />
                                </Link>
                                <div className="auth-form-text mt-md-3" style={{ marginBottom: "4rem" }}>
                                    <h1>
                                        { (step <= 2 || step == 5) && "مرحباً بك في منصة التمويل" }
                                        { step == 3 && "السجل التجاري" }
                                        { step == 4 && "بيانات الشركة" }
                                        { step == 6 && "رسالة ترحيبية" }
                                    </h1>
                                    <p>
                                        هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى.
                                    </p>
                                </div>
                            </div>
                            <div className="auth-form-inputs">
                                {
                                    errors.length > 0 && (
                                        <div style={{ marginBottom: "2rem" }}>
                                            {
                                                errors.map((e, i) =>
                                                    (
                                                        <div className="alert alert-danger" style={{ marginBottom: "0.5rem" }} key={i}>
                                                            خطأ: { e }
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                }

                                {step == 1 && (<Step1 />)}
                                {step == 2 && (<Step2 />)}
                                {step == 3 && (<Step3 />)}
                                {step == 4 && (<Step4 />)}
                                {step == 5 && (<Step5 />)}
                                {step == 6 && (<Step6 />)}
                            </div>
                            <div style={{ paddingBottom: "4rem" }}>
                                <div className="auth-form-submit-button-container mb-3">
                                    {
                                        step == 6
                                            ? (
                                                <Link
                                                    className="auth-form-homepage-button btn"
                                                    type="button"
                                                    to={localStorage.getItem("job-id-redirect") ? "/jobs" : "/"}
                                                >
                                                    {
                                                        localStorage.getItem("job-id-redirect")
                                                            ? "أكمال التقديم علي الوظيفة"
                                                            : "الانتقال للصفحة الرئيسية"
                                                    }
                                                </Link>
                                            ) : (
                                                <>
                                                    <div className="auth-form-submit-button-container mb-3">
                                                        {
                                                            step != 1 && step < 5 && (
                                                                <button
                                                                    className="auth-form-homepage-button btn mb-3"
                                                                    onClick={() => previousPage()}
                                                                >
                                                                    السابق
                                                                </button>
                                                            )
                                                        }
                                                        <button
                                                            className={`auth-form-submit-button  ${ !isDataDone() && 'disabled'}`}
                                                            onClick={() => isDataDone() && nextPage()}
                                                        >
                                                            { step <= 2 && "أنشاء حساب" }
                                                            { (step > 2 && step < 5) && "تأكيد" }
                                                            { step == 5 && "التالي" }
                                                        </button>
                                                    </div>
                                                    {
                                                        step < 3 && (
                                                            <div className="create-account-container text-center">
                                                                <span>لديك حساب بالفعل؟ </span>
                                                                <Link to="/login" className="auth-form-anchors font-bold"> تسجيل الدخول</Link>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <SideAuth />
                </div>
            </div>
        </RegisterContext.Provider>
    );
}

export type { RegisterData, CompanyData };
export { RegisterContext };
export default Register;