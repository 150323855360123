import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Style.scss"
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../../constants/apiConstants';
interface LayoutProps {
    email: string
    phone: string
    website: string
    instagram: string
    whatsapp: string
    tiktok: string
    twitter: string
}

const Layout: FunctionComponent = () => {
    const [data, setData] = useState<LayoutProps>();
    const [isAuth, setIsAuth] = useState(Boolean(localStorage.getItem('jwt-token')));
    const [activeMenu, setActiveMenu] = useState(false);
    const [username, setUsername] = useState("");
    
    const logOut =()=>{
        localStorage.removeItem('jwt-token');
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    useEffect(() => {
        axios.get(API_BASE_URL + '/contacts')
            .then(function (response) {
                if (response.status == 200) {
                    setData(response.data.data)
                }
            })
            .catch(function (error) {
                console.log(error)
            });

        let token = localStorage.getItem('jwt-token');

        if (token && token != "") {
            axios.post(`${API_BASE_URL}/auth/refresh`,[], configAuthApi())
                .then(async function (response) {
                    if (response.status == 200) {
                        localStorage.setItem('jwt-token', response.data.data.token)

                        axios.get(`${API_BASE_URL}/auth/me`, configAuthApi())
                            .then(response => {
                                if (response.status == 200) {
                                    setUsername(response.data.data.name)
                                }
                            })
                            .catch(function (error) {
                                if (error.response.status != 400) {
                                    localStorage.removeItem("jwt-token");
                                }
                            });

                        setIsAuth(true)
                    }
                })
                .catch(function (error) {
                    localStorage.removeItem('jwt-token')   
                    setIsAuth(false)
                });
        } else {
            setIsAuth(false);
        }
    }, []);
    return (
        <>
            <nav id="my-nav">
                <div className="container">
                    <div className="nav-inner">
                        <div className="header-logo">
                            <Link to="">
                                <img
                                    src="/assets/images/logo.svg"
                                    alt="logo branding"
                                    style={{
                                        minWidth: "10%",
                                        maxWidth: isAuth ? "40vw" : "45vw"
                                    }}
                                />
                            </Link>
                        </div>
                        <div className="header-list">
                            <ul id="header-nav-list" className="mb-0">
                                <li className="my-nav-item">
                                    <Link className='my-nav-link' to={""}>
                                        الرئيسية
                                    </Link>

                                </li>
                                <li className="my-nav-item">
                                    <Link className='my-nav-link' to={"/about"}>من نحن</Link>
                                </li>
                                <li className="my-nav-item">
                                    <Link className='my-nav-link' to={"/faq"}>الأسئلة الشائعة</Link>
                                </li>
                                <li className="my-nav-item">
                                    <Link className='my-nav-link' to={"/partners"}>الشركاء</Link>
                                </li>
                                <li className="my-nav-item">
                                    <Link to={"/blog"} className="my-nav-link">المدونة</Link>
                                </li>
                                <li className="my-nav-item">
                                    <Link to="/contact-us" className="my-nav-link">تواصل معنا</Link>
                                </li>
                                <li className="my-nav-item">
                                    <Link to="/jobs" className="my-nav-link">الوظائف</Link>
                                </li>
                            </ul>
                        </div>
                        <div id="mobile-btn-container">
                            {
                                isAuth && (
                                    <h5 style={{ fontSize: "2vw", maxWidth: "30vw", marginLeft: "0.5vw", overflowWrap: "break-word" }}>مرحبا، { username }</h5>
                                )
                            }
                            <button id="mobile-menu-btn" onClick={()=> setActiveMenu(!activeMenu)}>
                                <img src="/assets/images/menu-icon.svg" width="25px" alt="menu icon button" />
                            </button>
                        </div>
                        {isAuth ? (                            
                            <div id="is-auth" className="header-cta">
                                <h5 style={{ fontSize: "1.05vw", maxWidth: "12vw", marginLeft: "1vw", marginBottom: "-0.1rem", overflowWrap: "break-word" }}>مرحبا، { username }</h5>
                                <a onClick={()=>{logOut()}} className='cta cta-thic' href='#'>تسجيل خروج</a>
                            </div>) :(
                            <div className="header-cta">
                                <Link className='cta cta-thic' to={"/login"}>تسجيل دخول </Link>
                                <Link className="cta cta-light ms-3" to={"/register"}>انشاء حساب </Link>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${activeMenu && 'active'} mobile-menu`} id="mobile-menu">
                    <ul className="list-unstyled" id="mobile-menu-list">
                        <li className="mobile-menu-item">
                            <Link className='mobile-menu-link' to={"/"} onClick={()=> setActiveMenu(false)}>الرئيسية</Link>
                        </li>
                        <li className="mobile-menu-item">
                            <Link className='mobile-menu-link' to={"/about"} onClick={()=> setActiveMenu(false)}>من نحن</Link>
                        </li>
                        <li className="mobile-menu-item">
                            <Link className='mobile-menu-link' to={"/faq"} onClick={()=> setActiveMenu(false)}>الأسئلة الشائعة</Link>
                        </li>
                        <li className="mobile-menu-item">
                            <Link className='mobile-menu-link' to={"/partners"} onClick={()=> setActiveMenu(false)}>الشركاء</Link>
                        </li>
                        <li className="mobile-menu-item">
                            <Link to={"/jobs"} className="mobile-menu-link" onClick={()=> setActiveMenu(false)}>الوظائف</Link>
                        </li>
                        <li className="mobile-menu-item">
                            <Link to={"/blog"} className="mobile-menu-link" onClick={()=> setActiveMenu(false)}>المدونة</Link>
                        </li>
                        <li className="mobile-menu-item">
                            <Link to={"/contact-us"} className="mobile-menu-link" onClick={()=> setActiveMenu(false)}>تواصل معنا</Link>
                        </li>
                        {
                            isAuth ? (
                                <li className="mobile-menu-item">
                                    <a onClick={()=>{logOut()}} className='mobile-menu-link' href='#'>تسجيل خروج</a>
                                </li>
                            ) : (
                                <>
                                    <li className="mobile-menu-item">
                                        <Link className='mobile-menu-link' to={"/login"}>تسجيل دخول </Link>
                                    </li>
                                    <li className="mobile-menu-item">
                                        <Link className="mobile-menu-link" to={"/register"}>انشاء حساب </Link>
                                    </li>
                                </>
                            )
                        }
                    </ul>
                </div>
            </nav>
            <Outlet context={[isAuth]} />
            <footer>
                <div className="container">
                    <div className="row g-5">
                        <div className="col-12 col-lg-5">
                            <div className="footer-logo">
                                <Link to="" onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                    <img src="/assets/images/logo.svg" alt="footer-logo" />
                                </Link>
                            </div>
                            <p className="footer-text">
                                نحن شركة مبتكرة ومتخصصة في تقديم حلول مالية مرنة تلبي احتياجات عملائنا. من خلال منصتنا الرقمية
                                المتقدمة، نوفر لعملائنا إمكانية الوصول إلى مجموعة متنوعة من خيارات التمويل، مما يساعدهم على
                                تحقيق أهدافهم المالية بكل سهولة وراحة.
                            </p>
                            <ul className="list-unstyled d-flex">
                                <li className="social-link-item me-3">
                                    <a target='_blank' href={data?.twitter} className="social-link-anchor">
                                        <img src="/assets/images/x.svg" alt="" />
                                    </a>
                                </li>

                                <li className="social-link-item me-3">
                                    <a target='_blank' href={`https://wa.me/${data?.whatsapp}`} className="social-link-anchor">
                                        <img src="/assets/images/whatsapp.svg" alt="" />
                                    </a>
                                </li>
                                <li className="social-link-item me-3">
                                    <a target='_blank' href={data?.tiktok} className="social-link-anchor">
                                        <img src="/assets/images/tiktok.svg" alt="" />
                                    </a>
                                </li>

                                <li className="social-link-item me-3">
                                    <a target='_blank' href={data?.instagram} className="social-link-anchor">
                                        <img src="/assets/images/instagram.svg" alt="" />
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div className="col-md-1 d-none d-lg-block"></div>
                        <div className="col-12 col-lg-3">
                            <h5 className="footer-heading">
                                الوصول السريع
                            </h5>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <ul className="list-unstyled">
                                        <li className="footer-nav-item">
                                            <Link className='footer-nav-link' to={""} onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                                الرئيسية
                                            </Link>
                                        </li>
                                        <li className="footer-nav-item">
                                            <Link className='footer-nav-link' to={"/partners"} onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                                الشركاء
                                            </Link>
                                        </li>
                                        <li className="footer-nav-item">
                                            <Link className='footer-nav-link' to={"/faq"} onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                                الأسئلة الشائعة
                                            </Link>
                                        </li>
                                        <li className="footer-nav-item">
                                            <Link to={"/contact-us"} className="footer-nav-link" onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                                تواصل معنا
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <ul className="list-unstyled">
                                        <li className="footer-nav-item">
                                            <Link className='footer-nav-link' to={"/about"}  onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                                من نحن
                                            </Link>
                                        </li>
                                        <li className="footer-nav-item">
                                            <Link to={"/blog"} className="footer-nav-link"  onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                                المدونة
                                            </Link>
                                        </li>
                                        <li className="footer-nav-item">
                                            <a href="" className="footer-nav-link">
                                                الدعم
                                            </a>
                                        </li>
                                        <li className="footer-nav-item">
                                            <Link to={"/privacy-policy"} className="footer-nav-link"  onClick={() => {setActiveMenu(false); window.scrollTo(0, 0)}}>
                                                الأمان و الخصوصية
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <h5 className="footer-heading">
                                معلومات التواصل
                            </h5>
                            <div className="row">
                                <div className="col-12">
                                    <ul className="list-unstyled">
                                        <li className="footer-nav-item">
                                            <a href={`mailto:${ data?.email }`} className="footer-nav-link">
                                                <img src="/assets/images/email-icon.svg" alt="phone icon" className="me-2" />
                                                {data?.email}
                                            </a>
                                        </li>
                                        <li className="footer-nav-item">
                                            <a href={`tel:${ data?.phone }`} className="footer-nav-link">
                                                <img src="/assets/images/phone-icon.svg" alt="phone icon" className="me-2" />
                                                {data?.phone}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bg-white text-center py-2">
                <h6 className="mb-0">
                    جميع الحقوق محفوظة لمنصة التمويل © 2024
                </h6>
            </div>



        </>
    );
}

export default Layout;