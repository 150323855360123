import React, { useContext, useEffect, useRef, useState } from 'react';
import { FunctionComponent } from "react";
import { RegisterContext } from '../../pages/Register/Register';
import IntlTelInput from 'react-intl-tel-input';

const Step2: FunctionComponent = () => {
    const { user, setUser, validators } = useContext(RegisterContext);
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [isConfirmPasswordShow, setConfirmPasswordShow] = useState(false);

    const phoneRef = useRef<IntlTelInput>(null);

    useEffect(() => {
        setTimeout(() => {
            if (
                user?.mobile &&
                user?.phone
            ) {
                setUser?.({ ...user, phone: user.mobile });
            }
        }, 300);
    }, []);

    const isPhoneValid = () => !user?.phone || user.phone.length == 0 || validators?.user.phone();
    const isEmailValid = () => !user?.email || user.email.length == 0 || validators?.user.email();
    const isPasswordValid = () => !user?.password || user.password.length == 0 || validators?.user.password();
    const isConfirmValid = () => !user?.confirm || user.confirm.length == 0 || validators?.user.confirm();

    const setPhone = (v: string) => {
        let code = phoneRef.current?.state.dialCode.trim() ?? "+966";
        let phone = v.replaceAll(" ", "");
        let mobile = code + phone.replace(code, "");
        setUser?.({ ...user, phone, mobile });
    }

    const setUserField = (field: string, value: string) => setUser?.({ ...user, [field]: value });
    const setAgree = (value: boolean) => setUser?.({ ...user, "agree": value });

    return (
        <div className="row">
            <div className="col-12">
                <div className="auth-form-elements-group">
                    <label htmlFor="phone" className="auth-form-label d-block">رقم الهاتف</label>
                    <div className='position-relative phone-dropdown' dir='ltr'>
                        <IntlTelInput
                            ref={phoneRef}
                            inputClassName={`${ !isPhoneValid() && 'was-validated'} auth-form-text-input`}
                            useMobileFullscreenDropdown
                            separateDialCode
                            preferredCountries={["sa"]}
                            defaultCountry="sa"
                            fieldId="mobile"
                            fieldName="mobile"
                            telInputProps={{
                                onKeyPress: (e) => e.charCode != 43 &&
                                    !(e.charCode >= 48 && e.charCode <= 57)
                                    && e.preventDefault()
                            }}
                            placeholder="أدخل رقم الهاتف هنا"
                            value={user?.phone}
                            onSelectFlag={(v) => setPhone(v)}
                            onPhoneNumberChange={(_, v) => setPhone(v)}
                        />
                    </div>
                    {
                        !isPhoneValid() && (
                            <p className="error-text">يجب كتابة رقم هاتف بشكل صحيح.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12">
                <div className="auth-form-elements-group">
                    <label htmlFor="email" className="auth-form-label">البريد الالكتروني</label>
                    <input
                        id="email"
                        className={`${!isEmailValid() && 'was-validated'} auth-form-text-input`}
                        name="email"
                        type="email"
                        placeholder="أدخل البريد الالكتروني هنا"
                        value={user?.email}
                        onChange={(e) => setUserField("email", e.currentTarget.value)}
                    />
                    {
                        !isEmailValid() && (
                            <p className="error-text">يجب كتابة البريد الالكتروني بشكل صحيح.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="password" className="auth-form-label">كلمة المرور</label>
                    <div className="position-relative">
                        <input
                            id="password"
                            className={`${!isPasswordValid() && 'was-validated'} auth-form-text-input`}
                            name="password"
                            type={isPasswordShow ? "text" : "password"}
                            placeholder="اكتب كلمة المرور هنا"
                            value={user?.password}
                            onChange={(e) => setUserField("password", e.currentTarget.value)}
                        />
                        <button className="show-hide-password-btn" onClick={() => setPasswordShow(!isPasswordShow)}>
                            {
                                isPasswordShow
                                    ? (
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    ) : (
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M14.83 9.17999C14.2706 8.61995 13.5576 8.23846 12.7813 8.08386C12.0049 7.92926 11.2002 8.00851 10.4689 8.31152C9.73758 8.61453 9.11264 9.12769 8.67316 9.78607C8.23367 10.4444 7.99938 11.2184 8 12.01C7.99916 13.0663 8.41619 14.08 9.16004 14.83" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12 16.01C13.0609 16.01 14.0783 15.5886 14.8284 14.8384C15.5786 14.0883 16 13.0709 16 12.01" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M17.61 6.39004L6.38 17.62C4.6208 15.9966 3.14099 14.0944 2 11.99C6.71 3.76002 12.44 1.89004 17.61 6.39004Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M20.9994 3L17.6094 6.39" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M6.38 17.62L3 21" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M19.5695 8.42999C20.4801 9.55186 21.2931 10.7496 21.9995 12.01C17.9995 19.01 13.2695 21.4 8.76953 19.23" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </g>
                                        </svg>
                                    )
                            }
                        </button>
                    </div>
                    {
                        !isPasswordValid() && (
                            <p className="error-text">يجب كتابة ٦ احرف علي الأقل وان لا يحتوي الا علي حروف وارقام.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="auth-form-elements-group">
                    <label htmlFor="confirm_password" className="auth-form-label">تأكيد كلمة المرور</label>
                    <div className="position-relative">
                        <input
                            id="confirm_password"
                            className={`${!isConfirmValid() && 'was-validated'} auth-form-text-input`}
                            name="confirm_password"
                            type={isConfirmPasswordShow ? "text" : "password"}
                            placeholder="اكتب كلمة المرور هنا"
                            value={user?.confirm}
                            onChange={(e) => setUserField("confirm", e.currentTarget.value)}
                        />
                        <button className="show-hide-password-btn" onClick={() => setConfirmPasswordShow(!isConfirmPasswordShow)}>
                            {
                                isConfirmPasswordShow
                                    ? (
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    ) : (
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M14.83 9.17999C14.2706 8.61995 13.5576 8.23846 12.7813 8.08386C12.0049 7.92926 11.2002 8.00851 10.4689 8.31152C9.73758 8.61453 9.11264 9.12769 8.67316 9.78607C8.23367 10.4444 7.99938 11.2184 8 12.01C7.99916 13.0663 8.41619 14.08 9.16004 14.83" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12 16.01C13.0609 16.01 14.0783 15.5886 14.8284 14.8384C15.5786 14.0883 16 13.0709 16 12.01" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M17.61 6.39004L6.38 17.62C4.6208 15.9966 3.14099 14.0944 2 11.99C6.71 3.76002 12.44 1.89004 17.61 6.39004Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M20.9994 3L17.6094 6.39" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M6.38 17.62L3 21" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M19.5695 8.42999C20.4801 9.55186 21.2931 10.7496 21.9995 12.01C17.9995 19.01 13.2695 21.4 8.76953 19.23" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </g>
                                        </svg>
                                    )
                            }
                        </button>
                    </div>
                    {
                        !isConfirmValid() && (
                            <p className="error-text">كلمة المرور ليست متطابقة.</p>
                        )
                    }
                </div>
            </div>
            <div className="col-12">
                <div className="auth-form-elements-group">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={user?.agree}
                            onChange={(e) => setAgree(e.currentTarget.checked)}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            بالنقر هنا فإنك توافق على <a href="/terms" target="_blank" style={{ textDecoration: "underline" }}>سياسة
                                الخصوصية</a>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step2;