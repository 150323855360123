import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL, configAuthApi } from '../../constants/apiConstants';
import InfiniteScroll from 'react-infinite-scroll-component';
import BlogComponent from '../../components/Blog/Blog';
import moment from "moment-hijri";

interface BlogProps {
    id?: number
    title?: string
    description?: string
    image?: string
    created_at?: string
}
interface BlogsProps {
}

const weekdays: {[x: string]: string} = {
    'Saturday': 'السبت',
    'Sunday': 'الأحد',
    'Monday': 'الإثنين',
    'Tuesday': 'الثلاثاء',
    'Wednesday': 'الأربعاء',
    'Thursday': 'الخميس',
    'Friday': 'الجمعة'
};

const Blog: FunctionComponent<BlogsProps> = () => {
    const [blogs, setBlogs] = useState<BlogProps[]>([]);
    const [mainBlog, setMainBlog] = useState<BlogProps>({});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(2);

    const fetchData = ()=>{
        axios.get(`${API_BASE_URL}/articles?page=${page}`, configAuthApi())
            .then(response => {
                if (response.status == 200) {
                    let last_page = response.data.meta.last_page;
                    
                    if (page <= last_page) {
                        let data = response.data.data
                            .map((info: BlogProps) => {
                                if (info.created_at) {
                                    const hijriDate = moment(info.created_at).format('dddd iD iMMMM YYYY');
                                    const weekday = moment(info.created_at).format('dddd'); 
                                    info.created_at = hijriDate.replace(weekday, weekdays[weekday]);
                                }

                                return info
                            });

                        setPage(page + 1);
                        setLastPage(last_page);
                        setBlogs(blogs.concat(data));
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }
    useEffect(() => {
        axios.get(`${API_BASE_URL}/articles`, configAuthApi())
            .then(function (response) {
                if (response.status == 200) {
                    let data = response.data.data
                        .map((info: BlogProps) => {
                            if (info.created_at) {
                                const hijriDate = moment(info.created_at).format('idddd iD iMMMM iYYYY');
                                const weekday = moment(info.created_at).format('dddd'); 
                                info.created_at = hijriDate.replace(weekday, weekdays[weekday]);
                            }

                            return info
                        });

                    setPage(page + 1);
                    setLastPage(response.data.meta.last_page);
                    setBlogs(data);
                    setMainBlog(data[1]);
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    },[]);
    
    return (
        <>
            <section id="blog" className="section-element">
                <div className="container">
                    <div className="section-header">
                        <p style={{ fontWeight: "bold" }}>
                            <Link to="" style={{ color: "inherit" }} className="text-decoration-none">
                                الرئيسية
                            </Link>
                        </p>
                        <h6>المدونة</h6>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-5 pb-5">
                            <div className="blogs-hero" style={{ backgroundImage: `url('${mainBlog.image}')`, backgroundColor: "#f8f8f8" }}>
                                <div className="blogs-hero-text">
                                    <p className="date-time">
                                        {mainBlog.created_at}
                                    </p>
                                    <h1 className="blogs-hero-heading">
                                        <a href=""> {mainBlog.title}</a>
                                    </h1>
                                    <p className="blogs-hero-subheading">
                                        {mainBlog.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="blogs-list">
                            {/* <div className="row"> */}
                                <InfiniteScroll
                                    className='row'
                                    dataLength={blogs.length} //This is important field to render the next data
                                    next={fetchData}
                                    hasMore={page <= lastPage}
                                    loader={
                                        <>
                                            {
                                                new Array(9).fill(null).map((_, i) => (
                                                    <div className="col-12 col-lg-4 mb-4 px-lg-4" key={i}>
                                                        <div className="single-blog-card">
                                                            <div className="w-100 skeleton-box" style={{ backgroundColor: "#f8f8f8", height: "16.5rem", borderRadius: "1rem" }}></div>
                                                            <div className="blog-item-text">
                                                                <p className="mt-3 m-0 blog-item-date-time">
                                                                    <span className="skeleton-box w-25"></span>
                                                                </p>
                                                                <h4 className="blog-item-heading my-2 mx-0">
                                                                    <span className="skeleton-box w-50"></span>
                                                                </h4>
                                                                <p className="blog-item-description">
                                                                    <span className="skeleton-box w-100"></span>
                                                                    <span className="skeleton-box w-100 my-1"></span>
                                                                    <span className="skeleton-box w-75"></span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }
                                    // below props only if you need pull down functionality
                                    refreshFunction={fetchData}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={50}
                                    pullDownToRefreshContent={<></>}
                                    releaseToRefreshContent={<></>}
                                >
                                    {blogs.map(v =>
                                        <BlogComponent key={v.id} id={v.id} title={v.title} image={v.image}
                                            description={v.description} created_at={v.created_at}
                                        />
                                    )}
                                </InfiniteScroll>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog;
export type {BlogProps}