import React from 'react';
import { FunctionComponent } from "react";

interface DownloadProps {

}

const Download: FunctionComponent<DownloadProps> = () => {

    return (
        <>
            <section id="download" className="section-element mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src="/assets/images/download-section-image.svg" alt="download image" className="img-fluid" />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center px-2 px-md-5">
                            <div className="section-text text-center text-md-start">
                                <h3 className="pe-0 pe-md-4">
                                    <span>قسّط</span> طلباتك بكل مرونة على أربعة دفعات متساوية
                                </h3>
                                <p>
                                    ادفع قيمة مشترياتك على أربعة أقساط متساوية دون أي تعقيدات. اختر التمويل المناسب وابدأ
                                    بالتسوق
                                    اليوم، حيث يمكنك سداد الدفعات الشهرية بكل راحة وفق جدول ميسر.
                                </p>
                                <div className="cta-container my-5">
                                    <a href="" className="cta cta-thic">
                                        حمل التطبيق
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Download;